import React, { useEffect, useState } from 'react'
import { useForm } from 'core/hooks/form'
import { Field } from 'core/components/form/field'
import { TextInput } from 'core/components/form/text-input'
import { ErrorMessage } from 'core/components/form/error-message'
import { ModalForm } from 'core/components/modal'
import { AsyncSelect } from 'core/components/form/async-select'
import { Select } from 'core/components/form/select'

const findById = (items, selectId) => {
  const encontred = items.find(item => selectId === item.id)
  if (encontred) {
    return true
  } else {
    return false
  }
}

export const ConfirmModal = ({
  route,
  service,
  entitySelected,
  show,
  refresh,
  modal,
  setShowModal,
}) => {
  const form = useForm(
    {
      displayName: ent => ent.name,
      initialEntity: {
        id: 0,
        workCity: '',
        secretary: '',
        workPlace: '',
        workFunction: '',
      },
      validate: entity => {
        const errors = {}

        if (!entity.userLocalId) {
          errors.userLocalId = 'Município é obrigatório'
        }

        if (!entity.secretaryId) {
          errors.secretaryId = 'Secretaria é obrigatório'
        }

        if (!entity.workPlaceId) {
          errors.workPlaceId = 'Local de Trabalho é obrigatório'
        }

        if (!entity.workFunctionId) {
          errors.workFunctionId = 'Função é obrigatório'
        }

        return errors
      },
    },
    route,
  )

  const { entity, errors, touched } = form
  const [secretaries, setSecretaries] = useState([])
  const [workplaces, setWorkplaces] = useState([])
  const [workfunctions, setWorkfunctions] = useState([])

  const registerAffiliation = ({
    modal,
    data: { id, name, userLocalId, secretaryId, workPlaceId, workFunctionId },
    callback,
    service,
  }) => {
    setShowModal(false)

    modal.confirm(
      `Deseja sindicalizar a solicitação de ${name}?`,
      confirmed =>
        confirmed &&
        service
          .addOrUpdate('affiliation', {
            affiliationId: id,
            userLocalId,
            secretaryId,
            workPlaceId,
            workFunctionId,
          })
          .then(callback)
          .catch(err => modal.alert(err.message)),
    )
  }

  useEffect(() => {
    if (entitySelected) {
      form.setValues(prev => ({
        ...prev,
        id: entitySelected.id || 0,
        workCity: entitySelected.workCity || '',
        secretary: entitySelected.secretary || '',
        workPlace: entitySelected.workPlace || '',
        workFunction: entitySelected.workFunction || '',
      }))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [entitySelected, show])

  useEffect(() => {
    if (entity.userLocalId) {
      service
        .getList('secretary', {
          filters: [
            {
              items: [
                {
                  name: 'localId',
                  comparer: 'Equals',
                  value: entity.userLocalId,
                },
                {
                  name: 'disabledAt',
                  comparer: 'Equals',
                  value: null,
                },
              ],
            },
          ],
          sort: [['description', 'ASC']],
          usePager: false,
        })
        .then(({ items }) => {
          if (items.length > 0) {
            setSecretaries(items)
            form.setValues(prev => ({
              ...prev,
              secretaryId: entity.secretaryId
                ? findById(items, entity.secretaryId)
                  ? entity.secretaryId
                  : items[0].id
                : items[0].id,
            }))
          } else {
            form.setValues(prev => ({
              ...prev,
              secretaryId: '',
              workPlaceId: '',
            }))
            setSecretaries([])
            setWorkplaces([])
          }
        })
    } else {
      form.setValues(prev => ({
        ...prev,
        secretaryId: '',
        workPlaceId: '',
      }))
      setSecretaries([])
      setWorkplaces([])
    }

    // eslint-disable-next-line
  }, [entity.userLocalId])

  useEffect(() => {
    if (entity.userLocalId) {
      service
        .getList('workplace', {
          filters: [
            {
              items: [
                {
                  name: 'WorkPlace.localId',
                  comparer: 'Equals',
                  value: entity.userLocalId,
                },
              ],
            },
          ],
          sort: [['description', 'ASC']],
          usePager: false,
        })
        .then(({ items }) => {
          if (items.length > 0) {
            setWorkplaces(items)
            form.setValues(prev => ({
              ...prev,
              workPlaceId: entity.workPlaceId
                ? findById(items, entity.workPlaceId)
                  ? entity.workPlaceId
                  : ''
                : items[0].id,
            }))
          } else {
            form.setValues(prev => ({
              ...prev,
              workPlaceId: '',
            }))
            setWorkplaces([])
          }
        })
    } else {
      form.setValues(prev => ({
        ...prev,
        workPlaceId: '',
      }))
      setWorkplaces([])
    }
    // eslint-disable-next-line
  }, [entity.userLocalId])

  useEffect(() => {
    if (entity.userLocalId) {
      service
        .getList('workfunction', {
          filters: [
            {
              items: [
                {
                  name: 'WorkFunction.localId',
                  comparer: 'Equals',
                  value: entity.userLocalId,
                },
              ],
            },
          ],
          sort: [['description', 'ASC']],
          usePager: false,
        })
        .then(({ items }) => {
          if (items.length > 0) {
            setWorkfunctions(items)
            form.setValues(prev => ({
              ...prev,
              workFunctionId: entity.workFunctionId
                ? findById(items, entity.workFunctionId)
                  ? entity.workFunctionId
                  : ''
                : items[0].id,
            }))
          } else {
            form.setValues(prev => ({
              ...prev,
              workFunctionId: '',
            }))
            setWorkfunctions([])
          }
        })
    } else {
      form.setValues(prev => ({
        ...prev,
        workFunctionId: '',
      }))
      setWorkfunctions([])
    }
    // eslint-disable-next-line
  }, [entity.userLocalId])

  const closeModal = () => {
    setShowModal(false)
  }

  return (
    <ModalForm
      isLarge
      title={'Confirmação dos Dados de Trabalho'}
      show={show}
      fetching={form.fetching}
      submitting={form.submitting}
      resetForm={form.resetForm}
      closeAction={closeModal}
      onSubmit={() => {
        form.handleSubmit(data => {
          registerAffiliation({
            modal,
            data: {
              id: entity.id,
              name: entitySelected?.name,
              userLocalId: entity.userLocalId,
              secretaryId: entity.secretaryId,
              workPlaceId: entity.workPlaceId,
              workFunctionId: entity.workFunctionId,
            },
            callback: refresh,
            service,
          })
        })
      }}
    >
      <div className="row">
        <div className="col-lg">
          <Field label="Município">
            <TextInput
              value={entity.workCity}
              disabled={true}
              onChange={(workCity, type) =>
                form.handleChange({
                  path: 'workCity',
                  type,
                  values: prev => ({
                    ...prev,
                    workCity,
                  }),
                })
              }
            />
          </Field>
        </div>
        <div className="col-lg">
          <Field label="Secretaria">
            <TextInput
              value={entity.secretary}
              disabled={true}
              onChange={(secretary, type) =>
                form.handleChange({
                  path: 'secretary',
                  type,
                  values: prev => ({
                    ...prev,
                    secretary,
                  }),
                })
              }
            />
          </Field>
        </div>
        <div className="col-lg">
          <Field label="Local de Trabalho">
            <TextInput
              value={entity.workPlace}
              disabled={true}
              onChange={(workPlace, type) =>
                form.handleChange({
                  path: 'workPlace',
                  type,
                  values: prev => ({
                    ...prev,
                    workPlace,
                  }),
                })
              }
            />
          </Field>
        </div>
        <div className="col-lg">
          <Field label="Cargo ou Função">
            <TextInput
              value={entity.workFunction}
              disabled={true}
              onChange={(workFunction, type) =>
                form.handleChange({
                  path: 'workFunction',
                  type,
                  values: prev => ({
                    ...prev,
                    workFunction,
                  }),
                })
              }
            />
          </Field>
        </div>
      </div>

      <div className="kt-separator kt-separator--space-sm" />
      <div className="kt-heading kt-heading--sm">Selecione</div>

      <div className="row">
        <div className="col-lg">
          <Field label="Município">
            <AsyncSelect
              meta={{
                error: errors.userLocalId,
                touched: touched.userLocalId,
              }}
              isClearable
              getId={({ id }) => id}
              getDisplay={({ description }) => description}
              getItems={value =>
                service
                  .getList('local', {
                    filters: [
                      {
                        items: [
                          {
                            name: 'description',
                            comparer: 'Like',
                            value,
                          },
                        ],
                      },
                    ],
                    sort: [['description', 'ASC']],
                    usePager: false,
                  })
                  .then(({ items }) => items)
              }
              selected={entity.userLocalId}
              onChange={local => {
                form.handleChange({
                  path: 'userLocalId',
                  values: prev => ({
                    ...prev,
                    userLocalId: local ? local.id : 0,
                  }),
                })
              }}
            />
          </Field>
        </div>
        <div className="col-lg">
          <Field label="Secretaria">
            <Select
              meta={{
                error: errors.secretaryId,
                touched: touched.secretaryId,
              }}
              items={secretaries}
              selected={entity.secretaryId}
              getId={({ id }) => id}
              getDisplay={({ description }) => description}
              onChange={secretary => {
                form.handleChange({
                  path: 'secretary',
                  values: prev => ({
                    ...prev,
                    secretaryId: secretary.id,
                  }),
                })
              }}
            />
          </Field>
        </div>
        <div className="col-lg">
          <Field label="Local de Trabalho">
            <Select
              meta={{
                error: errors.workPlaceId,
                touched: touched.workPlaceId,
              }}
              items={workplaces}
              selected={entity.workPlaceId}
              getId={({ id }) => id}
              getDisplay={({ description }) => description}
              onChange={workPlace => {
                form.handleChange({
                  path: 'workPlace',
                  values: prev => ({
                    ...prev,
                    workPlaceId: workPlace.id,
                  }),
                })
              }}
            />
          </Field>
        </div>
        <div className="col-lg">
          <Field label="Função">
            <Select
              meta={{
                error: errors.workFunctionId,
                touched: touched.workFunctionId,
              }}
              items={workfunctions}
              selected={entity.workFunctionId}
              getId={({ id }) => id}
              getDisplay={({ description }) => description}
              onChange={workFunction => {
                form.handleChange({
                  path: 'workFunction',
                  values: prev => ({
                    ...prev,
                    workFunctionId: workFunction.id,
                  }),
                })
              }}
            />
          </Field>
        </div>
      </div>
      <ErrorMessage error={errors.global} />
    </ModalForm>
  )
}
