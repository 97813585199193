import { TextInput } from 'core/components/form/text-input'
import { PhoneMask } from 'core/constants'
import React from 'react'

export const UserTwoForm = ({ form: { entity, errors, touched, handleChange } }) => (
  <>
    <div className="form-group validated">
      <TextInput
        placeholder="Nome do Pai"
        value={entity.father}
        icon="fas fa-user"
        meta={{
          error: errors.father,
          touched: touched.father,
        }}
        onChange={(father, type) =>
          handleChange({
            path: 'father',
            type,
            values: prev => ({
              ...prev,
              father,
            }),
          })
        }
      />
    </div>

    <div className="form-group validated">
      <TextInput
        placeholder="Nome da Mãe"
        value={entity.mother}
        icon="fas fa-user"
        meta={{
          error: errors.mother,
          touched: touched.mother,
        }}
        onChange={(mother, type) =>
          handleChange({
            path: 'mother',
            type,
            values: prev => ({
              ...prev,
              mother,
            }),
          })
        }
      />
    </div>

    <div className="form-group validated">
      <TextInput
        placeholder="Nacionalidade"
        icon="fas fa-id-card"
        value={entity.nationality}
        meta={{
          error: errors.nationality,
          touched: touched.nationality,
        }}
        onChange={(nationality, type) =>
          handleChange({
            path: 'nationality',
            type,
            values: prev => ({
              ...prev,
              nationality,
            }),
          })
        }
      />
    </div>

    <div className="form-group validated">
      <TextInput
        placeholder="Telefone"
        type="tel"
        icon="fas fa-phone"
        mask={PhoneMask}
        value={entity.phone}
        meta={{
          error: errors.phone,
          touched: touched.phone,
        }}
        onChange={(phone, type) =>
          handleChange({
            path: 'phone',
            type,
            values: prev => ({
              ...prev,
              phone,
            }),
          })
        }
      />
    </div>

    <div className="form-group validated">
      <TextInput
        placeholder="Outras Informações"
        icon="fas fa-info"
        value={entity.others}
        meta={{
          error: errors.others,
          touched: touched.others,
        }}
        onChange={(others, type) =>
          handleChange({
            path: 'others',
            type,
            values: prev => ({
              ...prev,
              others,
            }),
          })
        }
      />
    </div>
  </>
)
