import { Select } from 'core/components/form/select'
import { TextInput } from 'core/components/form/text-input'
import { BrazilStates, CepMask } from 'core/constants'
import { onlyNumbers } from 'core/helpers/format'
import { getAddress } from 'helpers'
import React, { useRef, useState } from 'react'

export const AddressForm = ({ address, errors, touched, setValues, onBlurNumber, disabled }) => {
  const numberRef = useRef(null)
  const [loading, setLoading] = useState(false)

  return (
    <>
      <div className="form-group validated">
        <TextInput
          placeholder="CEP"
          disabled={disabled}
          meta={{
            error: errors.postalCode,
            touched: touched.postalCode,
            loading,
          }}
          mask={CepMask}
          value={address?.postalCode}
          onChange={(postalCode, type) => {
            setValues('postalCode', postalCode, type)

            if (type === 'blur' && onlyNumbers(postalCode).length === 8) {
              setLoading(true)
              getAddress(postalCode)
                .then(resp => {
                  if (!resp.erro) {
                    setValues({
                      complement: resp.complemento,
                      city: resp.localidade,
                      streetName: resp.logradouro,
                      district: resp.bairro,
                      state: resp.uf,
                    })

                    numberRef.current.focus()
                  }
                })
                .finally(() => setLoading(false))
            }
          }}
        />
      </div>

      <div className="form-group validated">
        <TextInput
          placeholder="Endereço"
          disabled={disabled}
          meta={{ error: errors.streetName, touched: touched.streetName }}
          value={address?.streetName}
          onChange={(streetName, type) => setValues('streetName', streetName, type)}
        />
      </div>

      <div className="form-group validated">
        <TextInput
          disabled={disabled}
          ref={numberRef}
          meta={{ error: errors.streetNumber, touched: touched.streetNumber }}
          value={address?.streetNumber}
          placeholder="Número"
          onChange={(streetNumber, type) => {
            setValues('streetNumber', streetNumber, type)
            if (type === 'blur' && onBlurNumber) {
              onBlurNumber()
            }
          }}
        />
      </div>

      <div className="form-group validated">
        <TextInput
          disabled={disabled}
          meta={{ error: errors.complement, touched: touched.complement }}
          value={address?.complement}
          placeholder="Complemento"
          onChange={(complement, type) => setValues('complement', complement, type)}
        />
      </div>

      <div className="form-group validated">
        <TextInput
          disabled={disabled}
          meta={{ error: errors.district, touched: touched.district }}
          value={address?.district}
          placeholder="Bairro"
          onChange={(district, type) => setValues('district', district, type)}
        />
      </div>

      <div className="form-group validated">
        <TextInput
          disabled={disabled}
          meta={{ error: errors.city, touched: touched.city }}
          value={address?.city}
          placeholder="Cidade"
          onChange={(city, type) => setValues('city', city, type)}
        />
      </div>

      <div className="form-group validated">
        <Select
          disabled={disabled}
          items={BrazilStates}
          selected={address?.state}
          getId={({ uf }) => uf}
          getDisplay={({ name }) => name}
          placeholder="Estado"
          onChange={({ uf: state, timeZone }) => setValues('state', state)}
        />
      </div>
    </>
  )
}
