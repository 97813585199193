import { TextInput } from 'core/components/form/text-input'
import React from 'react'

export const CodeForm = ({ form: { entity, errors, touched, handleChange } }) => (
  <>
    <div className="form-group validated">
      <TextInput
        placeholder="Código de acesso"
        value={entity.code}
        meta={{
          error: errors.code,
          touched: touched.code,
        }}
        onChange={(code, type) =>
          handleChange({
            path: 'code',
            type,
            values: prev => ({
              ...prev,
              code,
            }),
          })
        }
      />
    </div>
  </>
)
