import React, { useState } from 'react'
import { List } from 'core/components/list'
import { useRefresh } from 'core/hooks/refresh'
import { Field } from 'core/components/form/field'
import { TextInput } from 'core/components/form/text-input'
import { useFilters } from 'core/hooks/filter'
import { CellPhoneMask, CpfMask } from 'core/constants'
import { onlyNumbers } from 'core/helpers/format'
import { Button } from 'core/components/button'
import { Select } from 'core/components/form/select'
import { applyMask } from 'core/helpers/mask'
import { Link } from 'core/components/route'
import { ConfirmModal } from './ConfirmModal'
import { iframeDownload } from 'core/helpers/misc'

const getWhatsAppFormat = ({ cellPhone }) => {
  if (!cellPhone) {
    return ''
  }

  return (
    <>
      <span>{applyMask(CellPhoneMask, cellPhone)}</span>
      <Link
        className="btn btn-sm"
        target="_blank"
        to={`http://api.whatsapp.com/send?1=pt_BR&phone=55${cellPhone}`}
      >
        <i style={{ color: 'green' }} className="fab fa-whatsapp" />
      </Link>
    </>
  )
}

const columns = [
  { path: 'id', title: '#', textAlign: 'center' },
  { path: 'cpf', title: 'CPF', format: 'cpfcnpj' },
  { path: 'name', title: 'Nome' },
  { path: 'discardedAt', title: 'Situação', format: discardedFormatter },
  { path: 'createdAt', title: 'Data', format: 'date' },
  { path: 'cellPhone', title: 'Celular', format: getWhatsAppFormat },
]

function discardedFormatter(value) {
  if (value.userId) {
    return 'Afiliado'
  }

  return value.discardedAt ? 'Descartado' : 'Pendente'
}

const fields = [
  ...columns.map(c => c.path),
  'userId',
  'workCity',
  'secretary',
  'workPlace',
  'workFunction',
  'rg',
]

const discardAffiliation = ({ modal, data: { id, name }, callback, service }) =>
  modal.confirm(
    `Deseja descartar o solicitação de ${name}?`,
    confirmed =>
      confirmed &&
      service
        .remove('affiliation', id)
        .then(callback)
        .catch(err => modal.alert(err.message)),
  )

export const AffiliationList = ({ global, route, service, basename }) => {
  const { modal } = global

  const [showModal, setShowModal] = useState(false)
  const [downloading, setDownloading] = useState(false)
  const [entitySelected, setEntitySelected] = useState(null)
  const [dbFilters, setDbFilters] = useState({ name: '', cpf: '', status: 0 })

  const { setValues, filters } = useFilters(dbFilters, query => [
    {
      items: [
        { name: 'cpf', value: onlyNumbers(query.cpf), comparer: 'Like' },
        { name: 'name', value: query.name, comparer: 'Like' },
        query.status === 1
          ? {
              name: 'PublicAffiliation.discardedAt',
              value: null,
              comparer: 'Equals',
            }
          : {},
        query.status === 2
          ? {
              name: 'PublicAffiliation.discardedAt',
              value: null,
              comparer: 'NotEquals',
            }
          : {},
        query.status === 3
          ? {
              name: 'PublicAffiliation.userId',
              value: null,
              comparer: 'NotEquals',
            }
          : {},
        query.status === 1
          ? {
              name: 'PublicAffiliation.userId',
              value: null,
              comparer: 'Equals',
            }
          : {},
      ],
    },
  ])

  const fetching = useState(false)
  const refresh = useRefresh()

  function handleConsentDownload(entity) {
    const data = {
      name: entity.name,
      cpf: entity.cpf,
      rg: entity.rg,
      createdAt: entity.createdAt,
    }

    setDownloading(true)
    service
      .post('affiliation.consent', data, undefined, resp => resp.blob())
      .then(blob => iframeDownload(blob, 'Termo_de_Consentimento.pdf'))
      .catch(err => modal.alert(err.message))
      .finally(() => setDownloading(false))
  }

  function handleExport(entity) {
    const data = {
      id: entity.id,
    }

    setDownloading(true)
    service
      .post('affiliation.export', data, undefined, resp => resp.blob())
      .then(blob => iframeDownload(blob, 'Filiacao.pdf'))
      .catch(err => modal.alert(err.message))
      .finally(() => setDownloading(false))
  }

  return (
    <>
      <div className="kt-portlet kt-portlet--mobile">
        <div className="kt-portlet__head kt-portlet__head--lg">
          <div className="kt-portlet__head-label">
            <span className="kt-portlet__head-icon">
              <i className="kt-font-brand fas fa-user-tie" />
            </span>
            <h3 className="kt-portlet__head-title">Solicitações de Filiação</h3>
          </div>
        </div>
        <div className="kt-portlet__body kt-pb-0 position-relative">
          <div className="row">
            <div className="col-lg">
              <Field label="CPF">
                <TextInput
                  mask={CpfMask}
                  ignoreBlur
                  type="search"
                  value={dbFilters.cpf}
                  onChange={cpf => setDbFilters(prev => ({ ...prev, cpf }))}
                />
              </Field>
            </div>
            <div className="col-lg">
              <Field label="Nome">
                <TextInput
                  ignoreBlur
                  type="search"
                  value={dbFilters.name}
                  onChange={name => setDbFilters(prev => ({ ...prev, name }))}
                />
              </Field>
            </div>
            <div className="col-lg">
              <Field label="Situação">
                <Select
                  items={options}
                  selected={dbFilters.status}
                  getId={({ id }) => id}
                  getDisplay={({ name }) => name}
                  onChange={({ id }) =>
                    setDbFilters(prev => ({
                      ...prev,
                      status: id,
                    }))
                  }
                />
              </Field>
            </div>
          </div>
          <div className="row">
            <div className="col-lg kt-align-right">
              <Field>
                <Button
                  customClassName="btn-info btn-icon-sm"
                  icon="fas fa-search"
                  onClick={() => {
                    setValues(prev => ({
                      ...prev,
                      ...dbFilters,
                    }))
                  }}
                  title="Consultar"
                />
              </Field>
            </div>
          </div>

          {fetching[0] && (
            <div className="blockui-overlay">
              <div className="blockui" />
            </div>
          )}
        </div>
        <div className="kt-portlet__body kt-portlet__body--fit">
          <div className="kt-separator kt-separator--space-sm" />

          <List
            primaryKey="id"
            modal={modal}
            route={route}
            refresh={refresh}
            fetching={fetching}
            filters={filters}
            initialQuery={{ fields }}
            getItems={(query, signal) => service.getList('affiliation', query, signal)}
            actions={[
              {
                icon: 'fas fa-edit',
                title: 'Abrir',
                hideWhen: ent => ent.discardedAt || ent.userId,
                action: `${basename}/solicitacoes/:id`,
              },
              {
                icon: 'fas fa-trash',
                title: 'Descartar',
                hideWhen: ent => ent.discardedAt || ent.userId,
                action: ({ entity, forceRefresh }) =>
                  discardAffiliation({
                    callback: forceRefresh,
                    data: entity,
                    modal,
                    service,
                  }),
              },
              {
                icon: 'fas fa-file-pdf',
                title: 'Termo Consentimento',
                hideWhen: ent => ent.discardedAt || ent.userId || downloading,
                action: ({ entity }) => {
                  handleConsentDownload(entity)
                },
              },
              {
                icon: 'fas fa-file-export',
                title: 'Imprimir',
                hideWhen: ent => ent.discardedAt || ent.userId || downloading,
                action: ({ entity }) => {
                  handleExport(entity)
                },
              },
              {
                icon: 'fas fa-check',
                title: 'Sindicalizar',
                hideWhen: ent => ent.discardedAt || ent.userId,
                action: ({ entity }) => {
                  setEntitySelected(entity)
                  setShowModal(true)
                },
              },
            ]}
            columns={columns}
          />
        </div>
      </div>

      <ConfirmModal
        service={service}
        show={showModal}
        route={route}
        entitySelected={entitySelected}
        setShowModal={setShowModal}
        modal={modal}
        refresh={async () => {
          await global.refresh(true)
          refresh.force()
        }}
      />
    </>
  )
}

const options = [
  { id: 0, name: 'Todos' },
  { id: 1, name: 'Pendentes' },
  { id: 2, name: 'Descartados' },
  { id: 3, name: 'Afiliados' },
]
