import React, { useEffect, useState } from 'react'
import { useForm } from 'core/hooks/form'
import { Field } from 'core/components/form/field'
import { TextInput } from 'core/components/form/text-input'
import { requiredMessage, BirthdateMask } from 'core/constants'
import { ErrorMessage } from 'core/components/form/error-message'
import { ModalForm } from 'core/components/modal'
import { modalSubmit } from 'helpers'
import { DateInput } from 'core/components/form/date-input'
import { Button } from 'core/components/button'
import { Gif } from 'components/gifDoorPrize'

export const DoorPrizeForm = ({ route, service, basename, id, show, refresh }) => {
  const form = useForm(
    {
      displayName: ent => ent.description,
      initialEntity: {
        id: 0,
        description: '',
        createdAt: new Date(),
        times: `${new Date().getHours()}:${new Date().getMinutes()}`,
        raffling: false,
      },
      validate: values => {
        const errors = {}
        if (!values.description) errors.description = requiredMessage
        else if (values.description.length > 50)
          errors.description = 'A descrição deve ter no máximo 50 caracteres.'
        return errors
      },
    },
    route,
  )

  const { entity, errors, touched } = form
  const monName = [
    'janeiro',
    'fevereiro',
    'março',
    'abril',
    'maio',
    'junho',
    'agosto',
    'outubro',
    'novembro',
    'dezembro',
  ]
  const now = new Date()

  useEffect(() => {
    if (form.hasId) {
      form.handleFetch({
        action: (key, ac) => service.getById('doorprize', id, ac.signal),
        errorFn: err => form.setErrors({ global: err.message }),
      })
    }
    // eslint-disable-next-line
  }, [id])
  const [users, setUsers] = useState([])
  useEffect(() => {
    service
      .getList('user', {
        filters: [
          {
            items: [
              {
                comparer: 'Equals',
                name: 'User.disaffiliated',
                value: false,
              },
              { name: 'User.disabledAt', value: null, comparer: 'Equals' },
            ],
          },
        ],
        sort: [['name', 'ASC']],
        usePager: false,
      })
      .then(({ items }) => {
        if (items.length > 0) {
          setUsers(items)
        } else {
          setUsers([])
        }
      })

    // eslint-disable-next-line
  }, [id])
  useEffect(() => {
    if (entity.user) {
      service.getById('workfunction', entity.user.workFunctionId).then(workfunction => {
        form.setValues(prev => ({
          ...prev,
          workfunction: workfunction.description,
        }))
      })

      service.getById('secretary', entity.user.secretaryId).then(secretary => {
        form.setValues(prev => ({
          ...prev,
          secretary: secretary.description,
        }))
        service.getById('local', secretary.localId).then(local => {
          form.setValues(prev => ({
            ...prev,
            local: local.description,
          }))
        })
      })
    }

    // eslint-disable-next-line
  }, [entity.user])

  return (
    <ModalForm
      isXLarge
      title={form.displayName || 'Novo Sorteio'}
      show={show}
      fetching={form.fetching}
      submitting={form.submitting}
      resetForm={form.resetForm}
      closeAction={() => route.history.goBack()}
      onSubmit={() =>
        form.handleSubmit(data =>
          modalSubmit({ path: 'doorPrize', service, form, refresh, route, data }),
        )
      }
    >
      {!entity.raffling && !entity.user && (
        <>
          <div className="row">
            <div className="col-lg">
              <Field label="Titulo">
                <TextInput
                  meta={{
                    error: errors.description,
                    touched: touched.description,
                  }}
                  value={entity.description}
                  onChange={(description, type) =>
                    form.handleChange({
                      path: 'description',
                      type,
                      values: prev => ({
                        ...prev,
                        description,
                      }),
                    })
                  }
                />
              </Field>
            </div>
          </div>
          <div className="row">
            <div className="col-lg">
              <Field label="Data">
                <DateInput
                  customClassName="form-control-xl"
                  meta={{
                    error: errors.createdAt,
                    touched: touched.createdAt,
                  }}
                  mask={BirthdateMask}
                  onChange={(createdAt, type) => {
                    form.handleChange({
                      path: 'createdAt',
                      type,
                      values: prev => ({
                        ...prev,
                        createdAt,
                      }),
                    })
                  }}
                  value={entity.createdAt || null}
                />
              </Field>
            </div>
            <div className="col-lg">
              <Field label="Hora">
                <input
                  required
                  type="time"
                  className="form-control"
                  value={entity.times}
                  onChange={ev => {
                    const t = ev.target.value
                    form.handleChange({
                      path: 'times',
                      values: prev => ({
                        ...prev,
                        times: t,
                      }),
                    })
                  }}
                />
              </Field>
            </div>
          </div>
          <div className="kt-heading kt-heading--sm"></div>
          <div className="row">
            <div className="col-lg kt-align-right">
              <Button
                icon="fas fa-plus"
                customClassName="btn-info btn-icon-sm"
                title="Sortear Sevidor"
                onClick={() => {
                  if (!entity.user) {
                    form.setValues(prev => ({
                      ...prev,
                      user: users[Math.floor(Math.random() * users?.length)],
                      raffling: true,
                    }))
                  }
                  setTimeout(function() {
                    form.setValues(prev => ({
                      ...prev,
                      raffling: false,
                    }))
                  }, 10000)
                }}
              />
            </div>
          </div>
        </>
      )}
      {entity.raffling && (
        <div
          style={{
            backgroundColor: '#C91508',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Gif />
        </div>
      )}

      {entity.user && !entity.raffling && (
        <>
          <div className="font-title  text-center text-2xl md:text-5xl text-gray-500">
            O servidor sorteado foi:
          </div>
          <div className="text-center text-2xl md:text-2xl text-gray-500">
            <input
              type="text"
              style={{ display: 'none' }}
              id="resultToCopy"
              value={entity.user?.name}
            />
            <ul className="mt-10 font-bold justify-center flex flex-wrap">
              <li className="o-select bg-white p-5 m-2 border-solid hover:border-yellow-600 hover:text-yellow-600 text-yellow-500 transition cursor-pointer border-yellow-500 border-2 rounded-full shadow-inner transform hover:scale-125">
                {entity.user?.name} <br /> Função: {entity.workfunction} <br /> Secretaria:{' '}
                {entity.secretary}
              </li>
            </ul>
          </div>
          <br />
          <div class="no-select flex flex-wrap justify-center text-md">
            <div class="text-center text-2xl w-full text-gray-500">
              Informações sobre o sorteio:
            </div>
            <div class="row">
              <div class="rounded-lg w-auto shadow-lg border-solid border border-gray-200 p-2 md:p-5 text-center m-5 bg-white">
                <span class="text-gray-400">Data do Sorteio:</span>
                <br />
                <span class="text-xl md:text-xl">{`${now.getDate()} de ${
                  monName[now.getMonth() - 1]
                } de ${now.getFullYear()} ás ${now.getHours()}:${now.getMinutes()}`}</span>
              </div>
            </div>
            <div class="rounded-lg shadow-lg border-solid border border-gray-200 p-2 md:p-5 text-center m-5 bg-white">
              <span class="text-gray-400">Quantidade de servidores no sorteio</span>
              <br />
              <span class="text-xl md:text-3xl">{users.length}</span>
            </div>
          </div>
        </>
      )}
      <ErrorMessage error={errors.global} />
    </ModalForm>
  )
}
