import { TextInput } from 'core/components/form/text-input'
import { CpfMask, CellPhoneMask } from 'core/constants'
import React from 'react'

export const HomeForm = ({ form: { entity, errors, touched, handleChange } }) => (
  <>
    <div className="form-group validated">
      <TextInput
        placeholder="Nome Completo"
        icon="fas fa-id-card"
        value={entity.name}
        meta={{
          error: errors.name,
          touched: touched.name,
        }}
        onChange={(name, type) =>
          handleChange({
            path: 'name',
            type,
            values: prev => ({
              ...prev,
              name,
            }),
          })
        }
      />
    </div>

    <div className="form-group validated">
      <TextInput
        placeholder="Nome Social"
        icon="fas fa-user-tag"
        value={entity.socialName}
        meta={{
          error: errors.socialName,
          touched: touched.socialName,
        }}
        onChange={(socialName, type) =>
          handleChange({
            path: 'socialName',
            type,
            values: prev => ({
              ...prev,
              socialName,
            }),
          })
        }
      />
    </div>

    <div className="form-group validated">
      <TextInput
        placeholder="CPF"
        icon="fas fa-id-badge"
        mask={CpfMask}
        value={entity.cpf}
        meta={{
          error: errors.cpf,
          touched: touched.cpf,
        }}
        onChange={(cpf, type) =>
          handleChange({
            path: 'cpf',
            type,
            values: prev => ({
              ...prev,
              cpf,
            }),
          })
        }
      />
    </div>

    <div className="form-group validated">
      <TextInput
        placeholder="Celular"
        type="tel"
        icon="fas fa-phone"
        mask={CellPhoneMask}
        value={entity.cellPhone}
        meta={{
          error: errors.cellPhone,
          touched: touched.cellPhone,
        }}
        onChange={(cellPhone, type) =>
          handleChange({
            path: 'cellPhone',
            type,
            values: { cellPhone },
          })
        }
      />
    </div>
  </>
)
