export const BrazilStates = [
  { uf: 'AC', name: 'Acre', timeZone: -5 },
  { uf: 'AL', name: 'Alagoas', timeZone: -3 },
  { uf: 'AM', name: 'Amazonas', timeZone: -4 },
  { uf: 'AP', name: 'Amapá', timeZone: -3 },
  { uf: 'BA', name: 'Bahia', timeZone: -3 },
  { uf: 'CE', name: 'Ceará', timeZone: -3 },
  { uf: 'DF', name: 'Distrito Federal', timeZone: -3 },
  { uf: 'ES', name: 'Espírito Santo', timeZone: -3 },
  { uf: 'GO', name: 'Goiás', timeZone: -3 },
  { uf: 'MA', name: 'Maranhão', timeZone: -3 },
  { uf: 'MG', name: 'Minas Gerais', timeZone: -3 },
  { uf: 'MS', name: 'Mato Grosso do Sul', timeZone: -4 },
  { uf: 'MT', name: 'Mato Grosso', timeZone: -4 },
  { uf: 'PA', name: 'Pará', timeZone: -3 },
  { uf: 'PB', name: 'Paraíba', timeZone: -3 },
  { uf: 'PE', name: 'Pernambuco', timeZone: -3 },
  { uf: 'PI', name: 'Piauí', timeZone: -3 },
  { uf: 'PR', name: 'Paraná', timeZone: -3 },
  { uf: 'RJ', name: 'Rio de Janeiro', timeZone: -3 },
  { uf: 'RN', name: 'Rio Grande do Norte', timeZone: -3 },
  { uf: 'RO', name: 'Rondônia', timeZone: -4 },
  { uf: 'RR', name: 'Roraima', timeZone: -4 },
  { uf: 'RS', name: 'Rio Grande do Sul', timeZone: -3 },
  { uf: 'SC', name: 'Santa Catarina', timeZone: -3 },
  { uf: 'SP', name: 'São Paulo', timeZone: -3 },
  { uf: 'SE', name: 'Sergipe', timeZone: -3 },
  { uf: 'TO', name: 'Tocantins', timeZone: -3 },
]

export const getStateFullName = uf => BrazilStates.find(st => st.uf === uf).name

export const CnpjMask = [
  /\d/,
  /\d/,
  '.',
  /\d/,
  /\d/,
  /\d/,
  '.',
  /\d/,
  /\d/,
  /\d/,
  '/',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  '-',
  /\d/,
  /\d/,
]

export const CpfMask = [
  /\d/,
  /\d/,
  /\d/,
  '.',
  /\d/,
  /\d/,
  /\d/,
  '.',
  /\d/,
  /\d/,
  /\d/,
  '-',
  /\d/,
  /\d/,
]
export const CepMask = [/\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/]
export const CellPhoneMask = [
  '(',
  /\d/,
  /\d/,
  ')',
  ' ',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  '-',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
]
export const PhoneMask = [
  '(',
  /\d/,
  /\d/,
  ')',
  ' ',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  '-',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
]
export const requiredMessage = 'Esse campo é obrigatório.'
export const BirthdateMask = [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]

export const Banks = {
  BRASIL: '1',
  BANESPA: '33',
  CEF: '104',
  PAYROLL: '99',
}

export const BanksDescription = [
  { id: Banks.BRASIL, name: 'Banco do Brasil' },
  { id: Banks.BANESPA, name: 'Banespa' },
  { id: Banks.CEF, name: 'Caixa Econômica Federal' },
  { id: Banks.PAYROLL, name: 'Folha de Pagamento' },
]

export const CivilStatus = {
  SINGLE: '1',
  MARIED: '2',
  OFFICIAL: '3',
  SEPARETED: '4',
  WIDOWER: '5',
  STABLEUNION: '6',
  LOVING: '7',
}

export const CivilStatusDescription = [
  { id: CivilStatus.SINGLE, name: 'Solteiro(a)' },
  { id: CivilStatus.MARIED, name: 'Casado(a)' },
  { id: CivilStatus.OFFICIAL, name: 'Desquitado(a)' },
  { id: CivilStatus.SEPARETED, name: 'Divorcidado(a)' },
  { id: CivilStatus.WIDOWER, name: 'Viúvo(a)' },
  { id: CivilStatus.STABLEUNION, name: 'União estável' },
  { id: CivilStatus.LOVING, name: 'Amasiado(a)' },
]

export const Gender = {
  FEMALE: '1',
  MALE: '2',
  OTHER: '3',
}

export const GenderDescription = [
  { id: Gender.FEMALE, name: 'Feminino' },
  { id: Gender.MALE, name: 'Masculino' },
  { id: Gender.OTHER, name: 'Outro' },
]

export const Kinship = {
  HOLDER: null,
  SPOUSE: 1,
  SON: 2,
  DAD: 3,
  MOTHER: 4,
  FATHERINLAW: 5,
  MOTHERINLAW: 6,
  SIBLING: 7,
  GRANDCHILD: 8,
  NEPHEW: 9,
  UNCLE: 10,
  GODSON: 11,
  FIANCE: 12,
  DEPENDENT: 13,
  STEPFATHER: 14,
  STEPMOTHER: 15,
  STEPSON: 16,
  TUTELAGE: 17,
}

export const KinshipDescription = [
  { id: Kinship.HOLDER, name: 'Titular' },
  { id: Kinship.SPOUSE, name: 'Cônjuge' },
  { id: Kinship.SON, name: 'Filho' },
  { id: Kinship.DAD, name: 'Pai' },
  { id: Kinship.MOTHER, name: 'Mãe' },
  { id: Kinship.FATHERINLAW, name: 'Sogro' },
  { id: Kinship.MOTHERINLAW, name: 'Sogra' },
  { id: Kinship.SIBLING, name: 'Irmão(a)' },
  { id: Kinship.GRANDCHILD, name: 'Neto(a)' },
  { id: Kinship.NEPHEW, name: 'Sobrinho(a)' },
  { id: Kinship.UNCLE, name: 'Tio' },
  { id: Kinship.GODSON, name: 'Afilhado(a)' },
  { id: Kinship.FIANCE, name: 'Noivo(a)' },
  { id: Kinship.DEPENDENT, name: 'Dependente' },
  { id: Kinship.STEPFATHER, name: 'Padrasto' },
  { id: Kinship.STEPMOTHER, name: 'Madrasta' },
  { id: Kinship.STEPSON, name: 'Enteado(a)' },
  { id: Kinship.TUTELAGE, name: 'Tutelado' },
]

export const TypeScheduling = {
  PRESENTIAL: 1,
  PHONE: 2,
}

export const TypeSchedulingDescription = [
  { id: TypeScheduling.PRESENTIAL, name: 'Presencial' },
  { id: TypeScheduling.PHONE, name: 'Telefone' },
]

export const SchedulingStatus = {
  SCHEDULED: 1,
  CONFIRMED: 2,
  CANCELEDUSER: 3,
  ATTENDED: 4,
  CANCELEDLAWYER: 5,
  NOTATTENDED: 6,
}

export const SchedulingStatusDescription = [
  { id: SchedulingStatus.SCHEDULED, name: 'Agendado', class: 'notice-warning' },
  { id: SchedulingStatus.CONFIRMED, name: 'Confirmado', class: 'notice-success' },
  { id: SchedulingStatus.CANCELEDLAWYER, name: 'Cancelado Pelo Advogado', class: 'notice-waiting' },
  { id: SchedulingStatus.CANCELEDUSER, name: 'Cancelado Pelo Servidor', class: 'notice-waiting' },
  { id: SchedulingStatus.ATTENDED, name: 'Atendido', class: 'notice-primary' },
  { id: SchedulingStatus.NOTATTENDED, name: 'Não Compareceu', class: 'notice-waiting' },
]
