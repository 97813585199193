import { Button } from 'core/components/button'
import { ErrorMessage } from 'core/components/form/error-message'
import { CivilStatus, Gender } from 'core/constants'
import { isCPF, isPhone } from 'core/helpers/validate'
import { useForm } from 'core/hooks/form'
import React, { useState } from 'react'
import { Logo } from '../logo'
import { AddressForm } from './AddressForm'
import { UserForm } from './UserForm'
import { WorkForm } from './WorkForm'
import { iframeDownload, isObject } from 'core/helpers/misc'
import { HomeForm } from './HomeForm'
import { API } from 'service'
import { CodeForm } from './CodeForm'
import { UserTwoForm } from './UserTwoForm'

function handleConsentDownload(entity, modal) {
  const data = {
    name: entity.name,
    cpf: entity.cpf,
    rg: entity.rg,
    createdAt: new Date(),
  }

  API.post('affiliation.consent', data, undefined, resp => resp.blob())
    .then(blob => iframeDownload(blob, 'Termo_de_Consentimento.pdf'))
    .catch(err => modal.alert(err.message))
}

function handleExport(entity, modal) {
  const data = {
    id: entity.id,
  }

  API.post('affiliation.export', data, undefined, resp => resp.blob())
    .then(blob => iframeDownload(blob, 'Filiacao.pdf'))
    .catch(err => modal.alert(err.message))
}

function register(entity, route, form, global, setPage) {
  API.post('public.affiliation', {
    data: entity,
  })
    .then(({ id }) => {
      entity.id = id

      global.modal.alert(
        <>
          <p>Solicitação de filiação enviada com sucesso. Aguarde nosso contato.</p>
          <div className="kt-portlet__body kt-portlet__body--fit">
            <div className="kt-separator kt-separator--space-sm" />

            <ul>
              <li className="nav-item">
                <Button
                  icon="fas fa-file-pdf"
                  onClick={() => handleConsentDownload(entity, global.modal)}
                  disabled={false}
                  title="TERMO DE CONSENTIMENTO"
                />
              </li>
              <li className="nav-item">
                <Button
                  icon="fas fa-file-pdf"
                  onClick={() => handleExport(entity, global.modal)}
                  disabled={false}
                  title="IMPRIMIR FICHA"
                />
              </li>
            </ul>
          </div>
        </>,
      )
      form.resetForm()
      setPage('home')
      route.history.push(`/filiar-se`)
    })
    .catch(err => form.setErrors({ global: err.message }))
}


const Home = ({ form, setPage }) => {
  const { errors, entity } = form

  function disabled() {
    const noHasData = !entity.name || !entity.socialName || !entity.cpf || !entity.cellPhone
    const hasError = errors.name || errors.socialName || errors.cpf || errors.cellPhone

    return noHasData || hasError
  }

  function handleSubmit() {
    API.post('public.validate', {
      name: entity.name,
      socialName: entity.socialName,
      cpf: entity.cpf,
      phone: entity.cellPhone,
    })
      .then(() => {
        setPage('code')
      })
      .catch(err => form.setErrors({ global: err.message }))
  }

  return (
    <>
      <div className="login-description">
        <p>
          <span role="img" aria-label="Documento">
            📄
          </span>{' '}
          Tem interesse em se filiar junto ao Sintrapp? Os campos a seguir com seus dados de
          identificação e em breve entraremos em contato com você.
        </p>
        <p>
          <span role="img" aria-label="Localização">
            📞
          </span>{' '}
          Em caso de dúvidas, fale com o Sintrapp: (18) 3221-9946 ou (18) 99709-9735.
        </p>
      </div>

      <HomeForm form={form} />

      <Button
        type="button"
        customClassName="btn-primary btn-lg btn-block px-6 py-3"
        icon="fas fa-sign-in-alt"
        title="Prosseguir"
        disabled={disabled()}
        onClick={() => handleSubmit()}
      />

      <ErrorMessage error={errors.global} />
    </>
  )
}

const Code = ({ form, setPage }) => {
  const { errors, entity } = form

  function disabled() {
    const noHasData = !entity.code
    const hasError = errors.code

    return noHasData || hasError
  }

  function handleSubmit() {
    API.post('public.code', {
      cpf: entity.cpf,
      code: entity.code,
    })
      .then(() => {
        setPage('user')
      })
      .catch(err => form.setErrors({ global: err.message }))
  }

  return (
    <>
      <div className="login-description">
        <p>
          <span role="img" aria-label="Celular">
            📱
          </span>{' '}
          Enviamos um código de acesso para o seu celular. Informe o código abaixo para prosseguir.
        </p>
      </div>

      <CodeForm form={form} />

      <div className="d-flex justify-content-around">
        <Button
          type="button"
          icon="fas fa-arrow-left"
          customClassName="btn-primary btn-lg px-6 py-3 w-1/2"
          title="Voltar"
          onClick={() => setPage('home')}
        />

        <Button
          type="button"
          customClassName="btn-primary btn-lg px-6 py-3 w-1/2"
          icon="fas fa-sign-in-alt"
          title="Prosseguir"
          disabled={disabled()}
          onClick={() => handleSubmit()}
        />
      </div>

      <ErrorMessage error={errors.global} />
    </>
  )
}

const User = ({ form, setPage }) => {
  const { errors, entity } = form

  function disabled() {
    const noHasData = !entity.email || !entity.rg || !entity.birthDate || !entity.civilStatus
    const hasError = errors.email || errors.rg || errors.birthDate || errors.civilStatus

    return noHasData || hasError
  }

  return (
    <>
      <UserForm form={form} />

      <div className="d-flex justify-content-around">
        <Button
          type="button"
          icon="fas fa-arrow-left"
          customClassName="btn-primary btn-lg px-6 py-3 w-1/2"
          title="Voltar"
          onClick={() => setPage('home')}
        />

        <Button
          type="button"
          customClassName="btn-primary btn-lg px-6 py-3 w-1/2"
          icon="fas fa-sign-in-alt"
          title="Prosseguir"
          disabled={disabled()}
          onClick={() => setPage('usertwo')}
        />
      </div>

      <ErrorMessage error={errors.global} />
    </>
  )
}

const UserTwo = ({ form, setPage }) => {
  const { errors, entity } = form

  function disabled() {
    const noHasData = !entity.father || !entity.mother || !entity.nationality
    const hasError = errors.father || errors.mother || errors.nationality

    return noHasData || hasError
  }

  return (
    <>
      <UserTwoForm form={form} />

      <div className="d-flex justify-content-around">
        <Button
          type="button"
          icon="fas fa-arrow-left"
          customClassName="btn-primary btn-lg px-6 py-3 w-1/2"
          title="Voltar"
          onClick={() => setPage('usertwo')}
        />

        <Button
          type="button"
          customClassName="btn-primary btn-lg px-6 py-3 w-1/2"
          icon="fas fa-sign-in-alt"
          title="Prosseguir"
          disabled={disabled()}
          onClick={() => setPage('address')}
        />
      </div>

      <ErrorMessage error={errors.global} />
    </>
  )
}

const Address = ({ form, setPage }) => {
  const { entity, errors, touched } = form

  function disabled() {
    const noHasData =
      !entity.address.postalCode ||
      !entity.address.streetName ||
      !entity.address.streetNumber ||
      !entity.address.district ||
      !entity.address.city ||
      !entity.address.state
    const hasError =
      errors.postalCode ||
      errors.streetName ||
      errors.streetNumber ||
      errors.district ||
      errors.city ||
      errors.state

    return noHasData || hasError
  }

  return (
    <>
      <AddressForm
        address={entity.address}
        errors={errors}
        touched={touched}
        setValues={(property, value, type) => {
          if (isObject(property)) {
            form.handleChange({
              type: 'object',
              values: prev => ({
                ...prev,
                address: {
                  ...prev.address,
                  ...property,
                },
              }),
            })
          } else {
            entity.address[property] = value
            form.handleChange({
              path: property,
              type,
              values: prev => ({
                ...prev,
                address: entity.address,
              }),
            })
          }
        }}
      />

      <div className="d-flex justify-content-around">
        <Button
          type="button"
          icon="fas fa-arrow-left"
          customClassName="btn-primary btn-lg px-6 py-3 w-1/2"
          title="Voltar"
          onClick={() => setPage('user')}
        />

        <Button
          type="button"
          customClassName="btn-primary btn-lg px-6 py-3 w-1/2"
          icon="fas fa-sign-in-alt"
          title="Prosseguir"
          disabled={disabled()}
          onClick={() => setPage('work')}
        />
      </div>

      <ErrorMessage error={errors.global} />
    </>
  )
}

const Work = ({ form, setPage }) => {
  const { errors, submitting, entity } = form

  function disabled() {
    const noHasData =
      !entity.workCity ||
      !entity.secretary ||
      !entity.workPlace ||
      !entity.workFunction ||
      !entity.workPhone ||
      !entity.admittedAt ||
      !entity.salary
    const hasError =
      errors.workCity ||
      errors.secretary ||
      errors.workPlace ||
      errors.workFunction ||
      errors.workPhone ||
      errors.admittedAt ||
      errors.salary

    return noHasData || hasError || submitting
  }

  return (
    <>
      <WorkForm form={form} />

      <div className="d-flex justify-content-around">
        <Button
          type="button"
          icon="fas fa-arrow-left"
          customClassName="btn-primary btn-lg px-6 py-3 w-1/2"
          title="Voltar"
          onClick={() => setPage('address')}
        />

        <Button
          type="submit"
          customClassName="btn-primary btn-lg px-6 py-3 w-1/2"
          icon="fas fa-sign-in-alt"
          title="Inscrever"
          loading={submitting}
          disabled={disabled()}
        />
      </div>

      <ErrorMessage error={errors.global} />
    </>
  )
}

function getTitle(page) {
  if (page === 'user') return 'Dados Pessoais'
  if (page === 'usertwo') return 'Dados Pessoais'
  if (page === 'address') return 'Endereço'
  if (page === 'work') return 'Dados Profissionais'
  return 'Solicitação de Filiação'
}

export const PublicAffiliation = ({ route, global }) => {
  const [page, setPage] = useState('home')

  const form = useForm(
    {
      initialEntity: {
        name: '',
        socialName: '',
        cpf: '',
        cellPhone: '',
        phone: '',
        others: '',
        nationality: 'BRASILEIRO',
        father: '',
        mother: '',
        email: '',
        rg: '',
        birthDate: null,
        civilStatus: CivilStatus.SINGLE,
        gender: Gender.FEMALE,
        address: {
          postalCode: '',
          streetName: '',
          streetNumber: '',
          district: '',
          city: '',
          state: '',
          complement: '',
        },
        workCity: '',
        secretary: '',
        workPlace: '',
        workFunction: '',
        workPhone: '',
        admittedAt: null,
        salary: 0,
      },
      validate: values => {
        const errors = {}

        if (!values.name) {
          errors.name = 'Informe um nome'
        }

        if (!values.socialName) {
          errors.socialName = 'Informe um nome social'
        }

        if (!values.cpf) {
          errors.cpf = 'Informe um CPF'
        }

        if (!isCPF(values.cpf)) {
          errors.cpf = 'Digite um CPF válido'
        }

        if (!values.cellPhone) {
          errors.cellPhone = 'Informe um número de celular'
        }

        if (!isPhone(values.cellPhone)) {
          errors.cellPhone = 'Digite um número de celular válido'
        }

        if (!values.address.postalCode) {
          errors.postalCode = 'Informe um CEP'
        }

        if (!values.address.streetName) {
          errors.streetName = 'Informe um endereço'
        }

        if (!values.address.streetNumber) {
          errors.streetNumber = 'Informe um número'
        }

        if (!values.address.district) {
          errors.district = 'Informe um bairro'
        }

        if (!values.address.city) {
          errors.city = 'Informe uma cidade'
        }

        if (!values.address.state) {
          errors.state = 'Informe um estado'
        }

        if (!values.gender) {
          errors.gender = 'Informe um gênero'
        }

        if (!values.nationality) {
          errors.nationality = 'Informe uma nacionalidade'
        }

        if (!values.civilStatus) {
          errors.civilStatus = 'Informe um estado civil'
        }

        if (!values.rg) {
          errors.rg = 'Informe um RG'
        }

        if (!values.birthDate) {
          errors.birthDate = 'Informe uma data de nascimento'
        }

        if (!values.father) {
          errors.father = 'Informe o nome do pai'
        }

        if (!values.mother) {
          errors.mother = 'Informe o nome da mãe'
        }

        if (!values.email) {
          errors.email = 'Informe um e-mail'
        }

        if (!values.workCity) {
          errors.workCity = 'Informe uma cidade'
        }

        if (!values.secretary) {
          errors.secretary = 'Informe o sindicato'
        }

        if (!values.workPlace) {
          errors.workPlace = 'Informe o local de trabalho'
        }

        if (!values.workFunction) {
          errors.workFunction = 'Informe a função'
        }

        if (!values.workPhone) {
          errors.workPhone = 'Informe um telefone'
        }

        if (!values.admittedAt) {
          errors.admittedAt = 'Informe a data de admissão'
        }

        if (!values.salary) {
          errors.salary = 'Informe o salário'
        }

        return errors
      },
    },
    route,
  )

  return (
    <div className="login-wrapper">
      <div className="login-box">
        <div className="logo">
          <Logo isLogin route={route} />
        </div>

        <div className="login-title">
          <h3>{getTitle(page)}</h3>
        </div>

        <form
          className="login-form"
          onSubmit={ev => {
            ev.preventDefault()

            form.handleSubmit(entity => register(entity, route, form, global, setPage))
          }}
        >
          {page === 'home' && <Home form={form} setPage={setPage} />}
          {page === 'code' && <Code form={form} setPage={setPage} />}
          {page === 'user' && <User form={form} setPage={setPage} />}
          {page === 'usertwo' && <UserTwo form={form} setPage={setPage} />}
          {page === 'address' && <Address form={form} setPage={setPage} />}
          {page === 'work' && <Work form={form} setPage={setPage} />}
        </form>
      </div>
    </div>
  )
}
