import { DateInput } from 'core/components/form/date-input'
import { DecimalInput } from 'core/components/form/decimal-input'
import { TextInput } from 'core/components/form/text-input'
import { PhoneMask, BirthdateMask } from 'core/constants'
import React from 'react'

export const WorkForm = ({ form: { entity, errors, touched, handleChange } }) => (
  <>
    <div className="form-group validated">
      <TextInput
        placeholder="Município"
        value={entity.workCity}
        onChange={(workCity, type) =>
          handleChange({
            path: 'workCity',
            type,
            values: prev => ({
              ...prev,
              workCity,
            }),
          })
        }
      />
    </div>

    <div className="form-group validated">
      <TextInput
        placeholder="Secretaria"
        value={entity.secretary}
        onChange={(secretary, type) =>
          handleChange({
            path: 'secretary',
            type,
            values: prev => ({
              ...prev,
              secretary,
            }),
          })
        }
      />
    </div>

    <div className="form-group validated">
      <TextInput
        placeholder="Local de Trabalho"
        value={entity.workPlace}
        onChange={(workPlace, type) =>
          handleChange({
            path: 'workPlace',
            type,
            values: prev => ({
              ...prev,
              workPlace,
            }),
          })
        }
      />
    </div>

    <div className="form-group validated">
      <TextInput
        placeholder="Cargo ou Função"
        value={entity.workFunction}
        onChange={(workFunction, type) =>
          handleChange({
            path: 'workFunction',
            type,
            values: prev => ({
              ...prev,
              workFunction,
            }),
          })
        }
      />
    </div>

    <div className="form-group validated">
      <TextInput
        placeholder="Tel. do Trabalho"
        type="tel"
        icon="fas fa-phone"
        mask={PhoneMask}
        value={entity.workPhone}
        meta={{
          error: errors.workPhone,
          touched: touched.workPhone,
        }}
        onChange={(workPhone, type) =>
          handleChange({
            path: 'workPhone',
            type,
            values: prev => ({
              ...prev,
              workPhone,
            }),
          })
        }
      />
    </div>

    <div className="form-group validated">
      <DateInput
        placeholder="Data de Admissão"
        customClassName="form-control-xl"
        meta={{
          error: errors.admittedAt,
          touched: touched.admittedAt,
        }}
        mask={BirthdateMask}
        onChange={(admittedAt, type) => {
          handleChange({
            path: 'admittedAt',
            type,
            values: prev => ({
              ...prev,
              admittedAt,
            }),
          })
        }}
        value={entity.admittedAt || null}
      />
    </div>

    <div className="form-group validated">
      <DecimalInput
        meta={{
          error: errors.salary,
          touched: touched.salary,
        }}
        acceptEnter
        placeholder="Salário"
        customClassName="form-control-xl"
        icon="fas fa-dollar-sign"
        onChange={(salary, type) => {
          handleChange({
            path: 'salary',
            type,
            values: prev => ({
              ...prev,
              salary,
            }),
          })
        }}
        value={entity.salary}
      />
    </div>
  </>
)
