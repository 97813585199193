import React, { useEffect, useState } from 'react'
import { useForm } from 'core/hooks/form'
import { Field } from 'core/components/form/field'
import { ErrorMessage } from 'core/components/form/error-message'
import {
  TypeSchedulingDescription,
  SchedulingStatusDescription,
  CpfMask,
  CellPhoneMask,
  TypeScheduling,
  BirthdateMask,
} from 'core/constants'
import { Select } from 'core/components/form/select'
import { Switch } from 'core/components/form/switch'
import { ReadOnlyInput } from 'core/components/form/readonly-input'
import { useFilters } from 'core/hooks/filter'
import { useDebounce } from 'core/hooks/debounce'
import { TextInput } from 'core/components/form/text-input'
import { Button } from 'core/components/button'
import { LoadingCard } from 'core/components/loading-card'
import { classNames } from 'core/helpers/misc'
import { maskCpfCnpj } from 'core/helpers/mask'
import { toLocaleDate } from 'core/helpers/date'
import { Transition } from 'react-transition-group'
import { onlyNumbers } from 'core/helpers/format'
import { getDay } from 'date-fns'
import { DateInput } from 'core/components/form/date-input'
import { TextAreaInput } from 'core/components/form/textarea-input'
import { ModalPortal } from '../../core/components/modal'

const RenderPatient = ({ form, service, setAttendanceWithoutPatient, global }) => {
  const { setValues: setValuesForm, entity } = form
  const [items, setItems] = useState([])
  const { values, setValues: patientSearch, filters } = useFilters(
    {
      cpfCnpj: '',
      name: '',
    },
    query => [
      {
        items: [
          { name: 'cpf', value: onlyNumbers(query.cpfCnpj), comparer: 'Like' },
          { name: 'name', value: query.name, comparer: 'Like' },
        ],
      },
    ],
  )
  let erros = ''
  const patientFilters = useDebounce(filters, 300)
  const [fetching, setFetching] = useState(false)
  return (
    <>
      <div className="row">
        <div className="col-lg-2">
          <Switch
            id="justContact"
            className="button-add"
            label="Não Sindicalizado"
            checked={entity.justContact}
            onChange={justContact => {
              setAttendanceWithoutPatient(false)
              form.setErrors({ global: null })
              setValuesForm(prev => ({
                ...prev,
                justContact,
              }))
            }}
          />
        </div>
      </div>
      <br />
      {!entity.justContact && (
        <>
          <div className="row">
            <div className="col-lg">
              <Field label="CPF/CNPJ">
                <TextInput
                  mask={CpfMask}
                  ignoreBlur
                  type="search"
                  value={values.cpfCnpj}
                  onChange={cpfCnpj => patientSearch(prev => ({ ...prev, cpfCnpj }))}
                />
              </Field>
            </div>
            <div className="col-lg">
              <Field label="Nome do Servidor">
                <TextInput
                  ignoreBlur
                  type="search"
                  value={values.name}
                  onChange={name => patientSearch(prev => ({ ...prev, name }))}
                />
              </Field>
            </div>
          </div>
          <div className="row">
            <div className="col-lg kt-align-right">
              <Button
                icon="fas fa-search"
                customClassName="btn-info btn-icon-sm margin-left-5"
                title="Consultar"
                onClick={() => {
                  if (values.cpfCnpj === '' && values.name === '') {
                    form.setErrors({
                      global:
                        'Para busca de servidores, informe ao menos CPF/CNPJ ou Nome do Servidor',
                    })
                  } else {
                    setAttendanceWithoutPatient(false)
                    form.setErrors({ global: null })
                    setValuesForm(prev => ({
                      ...prev,
                      userId: null,
                    }))
                    setFetching(true)
                    service
                      .getList('user', {
                        sort: [['name', 'ASC']],
                        usePager: false,
                        filters: patientFilters,
                      })
                      .then(resp => setItems(resp.items))
                      .catch(err => form.setErrors({ global: err.message }))
                      .finally(() => setFetching(false))
                  }
                }}
              />
            </div>
          </div>
          {fetching ? (
            <div className="kt-portlet__body kt-pb-0 position-relative">
              <div className="row">
                <LoadingCard />
              </div>
            </div>
          ) : (
            <div className="kt-portlet__body kt-portlet__body--fit">
              <div
                className={classNames(
                  'report-irregular kt-datatable kt-datatable--default kt-datatable--brand',
                  {
                    'kt-datatable--loaded': !fetching || items.length > 0,
                    'table-loading': fetching,
                  },
                )}
              >
                <table className="kt-datatable__table">
                  <thead className="kt-datatable__head">
                    <tr className="kt-datatable__row">
                      <th className="kt-datatable__cell">
                        <span>CPF</span>
                      </th>
                      <th className="kt-datatable__cell">
                        <span>Nome</span>
                      </th>
                      <th className="kt-datatable__cell">
                        <span>Filiado/Desfiliado</span>
                      </th>
                      <th className="kt-datatable__cell">
                        <span> </span>
                      </th>
                    </tr>
                  </thead>
                  <tbody className="kt-datatable__body">
                    {items.map(i => (
                      <tr key={i.id} className="kt-datatable__row">
                        <td className="kt-datatable__cell">
                          <div>{maskCpfCnpj(i.cpf)}</div>
                        </td>
                        <td className="kt-datatable__cell">
                          <div>{i.name}</div>
                        </td>
                        <td className="kt-datatable__cell">
                          <div>{i.disaffiliated ? 'Desfiliado' : 'Filiado'}</div>
                        </td>
                        <td className="kt-datatable__cell">
                          <Button
                            icon="fas fa-calendar-alt"
                            customClassName="btn-info btn-icon-sm"
                            title=""
                            onClick={() => {
                              if (!i.disaffiliated) {
                                form.setErrors({ global: null })
                                setValuesForm(prev => ({
                                  ...prev,
                                  userName: i.name,
                                  userId: i.id,
                                  contactPhone: i.contactPhone || i.patientPhone,
                                  view: 'Scheduling',
                                  type: TypeScheduling.ATTENDANCE,
                                }))
                              } else form.setErrors({ global: 'Servidor Precisa Estar Filiado ' })
                            }}
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                {items.length === 0 && (
                  <div className="kt-datatable--error">Nenhum item foi encontrado.</div>
                )}
                {erros && <div className="kt-datatable--error">Servidor Precisa Estar Filiado</div>}
              </div>
              <div className="row">
                <div className="col-lg">
                  <Field label="Observações">
                    <TextAreaInput
                      value={entity.observation || ''}
                      onChange={(observation, type) =>
                        form.handleChange({
                          path: 'observation',
                          type,
                          values: prev => ({
                            ...prev,
                            observation,
                          }),
                        })
                      }
                      maxLenght={500}
                    />
                  </Field>
                </div>
              </div>
              <br />
            </div>
          )}
        </>
      )}
      {entity.justContact && (
        <>
          <div className="row">
            <div className="col-lg">
              <Field label="Nome do Servidor">
                <TextInput
                  value={entity.contactName || ''}
                  onChange={contactName =>
                    setValuesForm(prev => ({
                      ...prev,
                      contactName,
                    }))
                  }
                />
              </Field>
            </div>
            <div className="col-lg">
              <Field label="Telefone de contato">
                <TextInput
                  type="tel"
                  icon="fas fa-phone"
                  mask={CellPhoneMask}
                  value={entity.contactPhone || ''}
                  onChange={contactPhone =>
                    setValuesForm(prev => ({
                      ...prev,
                      contactPhone,
                    }))
                  }
                />
              </Field>
            </div>
            <div className="col-lg-1">
              <Button
                icon="fas fa-calendar-alt"
                customClassName="btn-info btn-icon-sm button-add"
                title=""
                onClick={() => {
                  form.setErrors({
                    global: null,
                  })
                  if (!entity.contactName) {
                    form.setErrors({
                      global: 'Nome do servidor é obrigatório.',
                    })
                  } else if (!entity.contactPhone) {
                    form.setErrors({
                      global: 'Telefone de contato é obrigatório.',
                    })
                  } else {
                    setValuesForm(prev => ({
                      ...prev,
                      view: 'Scheduling',
                      type: TypeScheduling.ATTENDANCE,
                    }))
                  }
                }}
              />
            </div>
          </div>
        </>
      )}
    </>
  )
}

const RenderScheduling = ({ form, service, startHourlies, endHourlies }) => {
  const { entity, errors, touched } = form
  const [startHourSelected, setStartHourSelected] = useState(0)
  const [endHourSelected, setEndHourSelected] = useState(0)
  const [hoursStart, setHoursStart] = useState([])
  const [hoursEnd, setHoursEnd] = useState([])

  useEffect(() => {
    setHoursStart([])
    for (let index = 0; index < startHourlies.length; index += 1) {
      const element = startHourlies[index]
      setHoursStart(prev => [...prev, { id: index, name: element }])
    }
    // eslint-disable-next-line
  }, [startHourlies])

  useEffect(() => {
    setHoursEnd([])
    for (let index = 0; index < endHourlies.length; index += 1) {
      const element = endHourlies[index]
      setHoursEnd(prev => [...prev, { id: index, name: element }])
    }
    // eslint-disable-next-line
  }, [endHourlies])

  useEffect(() => {
    const optionTimeIndex = startHourlies.indexOf(entity.start)
    setStartHourSelected(optionTimeIndex)
    // eslint-disable-next-line
  }, [entity.start])

  useEffect(() => {
    const optionTimeIndex = endHourlies.indexOf(entity.end)
    setEndHourSelected(optionTimeIndex)
    // eslint-disable-next-line
  }, [entity.end])

  return (
    <>
      <div className="row">
        <div className="col-lg">
          <Field label="Advogado">
            <ReadOnlyInput
              customClassName="form-control-xl"
              value={entity.lawyerProfessionalName}
            />
          </Field>
        </div>
        <div className="col-lg">
          <Field label="Sevidor">
            <ReadOnlyInput
              customClassName="form-control-xl"
              value={entity.userId !== null ? entity.userName : entity.contactName}
            />
          </Field>
        </div>
        <div className="col-lg">
          <Field label="Telefone">
            <TextInput
              type="tel"
              icon="fas fa-phone"
              mask={CellPhoneMask}
              value={entity.contactPhone}
              customClassName="form-control-xl"
              onChange={(contactPhone, type) =>
                form.handleChange({
                  path: 'contactPhone',
                  type,
                  values: { contactPhone },
                })
              }
            />
          </Field>
        </div>
      </div>
      <div className="row">
        <div className="col-lg">
          <Field label="Data">
            <DateInput
              customClassName="form-control-xl"
              mask={BirthdateMask}
              onChange={createdAt => {
                form.handleChange({
                  path: 'createdAt',
                  values: prev => ({
                    ...prev,
                    createdAt,
                  }),
                })
              }}
              value={entity.createdAt || null}
            />
          </Field>
        </div>
        <div className="col-lg">
          <Field label="Hora início">
            <Select
              items={hoursStart}
              placement="bottom"
              selected={startHourSelected}
              getId={({ id }) => id}
              getDisplay={({ name }) => name}
              onChange={start => {
                form.handleChange({
                  path: 'start',
                  values: prev => ({
                    ...prev,
                    start: start.name,
                    end: endHourlies[start.id],
                  }),
                })
              }}
            />
          </Field>
        </div>
        <div className="col-lg">
          <Field label="Hora fim">
            {form.hasId && <ReadOnlyInput customClassName="form-control-xl" value={entity.end} />}
            {!form.hasId && (
              <Select
                items={hoursEnd}
                placement="bottom"
                selected={endHourSelected}
                getId={({ id }) => id}
                getDisplay={({ name }) => name}
                onChange={end => {
                  form.handleChange({
                    path: 'end',
                    values: prev => ({
                      ...prev,
                      end: end.name,
                    }),
                  })
                }}
              />
            )}
          </Field>
        </div>
      </div>
      <div className="row">
        <div className="col-lg">
          <Field label="Tipo">
            <Select
              meta={{
                error: errors.type,
                touched: touched.type,
              }}
              placement="top"
              items={TypeSchedulingDescription}
              selected={entity.type}
              getId={({ id }) => id}
              getDisplay={({ name }) => name}
              onChange={type => {
                if (type.id === TypeScheduling.RETURN && entity.userId === null) {
                  form.setErrors({
                    global: 'Para selecionar RETORNO é preciso informar um servidor cadastrado!',
                  })
                } else {
                  form.setErrors({ global: null })
                  form.handleChange({
                    path: 'type',
                    values: prev => ({
                      ...prev,
                      type: type.id,
                      view: type.id === TypeScheduling.RETURN ? 'AttendanceReturn' : 'Scheduling',
                    }),
                  })
                }
              }}
            />
          </Field>
        </div>
        <div className="col-lg">
          <Field label="Status">
            <Select
              meta={{
                error: errors.status,
                touched: touched.status,
              }}
              placement="top"
              items={SchedulingStatusDescription}
              selected={entity.status}
              getId={({ id }) => id}
              getDisplay={({ name }) => name}
              onChange={status => {
                form.handleChange({
                  path: 'status',
                  values: prev => ({
                    ...prev,
                    status: status.id,
                  }),
                })
              }}
            />
          </Field>
        </div>
      </div>
    </>
  )
}

export const SchedulingModal = ({
  route,
  service,
  id,
  show,
  dateSelected,
  timeStartSelected,
  timeEndSelected,
  lawyerProfessionalNameSelected,
  lawyerIdSelected,
  basename,
  global,
  activeTimes,
}) => {
  const [paramsRoute, setParamsRoute] = useState({})
  const [attendanceWithoutPatient, setAttendanceWithoutPatient] = useState(false)
  const [activeTimesWeek, setActiveTimesWeek] = useState(activeTimes)
  const [startHourlies, setStartHourlies] = useState([])
  const [endHourlies, setEndHourlies] = useState([])
  const form = useForm(
    {
      displayName: ent => ent.id,
      initialEntity: {
        id: 0,
        lawyerId: 0,
        lawyerProfessionalName: '',
        userId: null,
        userName: '',
        createdAt: '',
        start: '',
        end: '',
        attendanceRoomId: null,
        type: TypeScheduling.ATTENDANCE,
        status: 1,
        view: 'Patients',
        contactName: null,
        contactPhone: null,
        justContact: false,
        patientPhone: '',
        attendanceReturnId: null,
        attendanceReturnCreatedAt: '',
        attendanceReturnProfessionalName: '',
        observation: '',
        relatedScheduling: null,
      },
      validate: values => {
        const errors = {}

        if (values.start === '') errors.global = 'A Hora de início é obrigatória.'

        return errors
      },
    },
    route,
  )

  const { entity, errors } = form
  const { state } = route.location

  useEffect(() => {
    if (state) {
      form.setValues(prev => ({
        ...prev,
        ...state,
      }))
      if (state.userId) {
        form.setValues(prev => ({
          ...prev,
          view: 'Scheduling',
        }))
      }
      setParamsRoute(state)
    }
    // eslint-disable-next-line
  }, [state])

  useEffect(() => {
    if (entity.justContact) {
      form.setValues(prev => ({
        ...prev,
        userId: null,
        userName: '',
      }))
    } else {
      form.setValues(prev => ({
        ...prev,
        contactName: null,
        contactPhone: null,
      }))
    }
    // eslint-disable-next-line
  }, [entity.justContact])

  useEffect(() => {
    if (form.hasId) {
      form.handleFetch({
        action: (key, ac) => service.getById('scheduling', key, ac.signal),
        errorFn: err => form.setErrors({ global: err.message }),
        mapper: s => ({
          ...s,
          userName: s.user !== null ? s.user.name : '',
          justContact: s.userId === null,
          attendanceReturnCreatedAt: toLocaleDate(s.attendanceReturnCreatedAt),
          view: 'Scheduling',
        }),
      })
    }
    // eslint-disable-next-line
  }, [id, route])

  useEffect(() => {
    if (!state) {
      form.resetForm()
      form.setValues(prev => ({
        ...prev,
        lawyerId: lawyerIdSelected,
        lawyerProfessionalName: lawyerProfessionalNameSelected,
        createdAt: dateSelected,
        start: timeStartSelected,
        end: timeEndSelected,
      }))
    }

    // eslint-disable-next-line
  }, [
    lawyerIdSelected,
    lawyerProfessionalNameSelected,
    dateSelected,
    timeStartSelected,
    timeEndSelected,
  ])

  useEffect(() => {
    setActiveTimesWeek(activeTimes)
  }, [activeTimes])

  useEffect(() => {
    if (entity.createdAt !== '') {
      const day = getDay(entity.createdAt)
      activeTimesWeek.forEach(schedule => {
        if (schedule.dayOfWeek === day) {
          setStartHourlies(schedule.hourlies)
          setEndHourlies(schedule.endHourlies)
        }
      })
    }
    // eslint-disable-next-line
  }, [entity.createdAt])

  useEffect(() => {
    if (entity.createdAt !== dateSelected) {
      form.setValues(prev => ({
        ...prev,
        start: startHourlies.lenght > 0 ? startHourlies[0] : '',
        end: endHourlies.lenght > 0 ? endHourlies[0] : '',
      }))
    }
    // eslint-disable-next-line
  }, [startHourlies])

  const resetForm = () => {
    const { lawyerId, lawyerProfessionalName, createdAt, start, end } = entity
    form.resetForm()
    form.setValues(prev => ({
      ...prev,
      lawyerId,
      lawyerProfessionalName,
      createdAt,
      start,
      end,
      type: TypeScheduling.ATTENDANCE,
    }))
  }

  const onSubmit = () => {
    if (endHourlies.indexOf(entity.end) - startHourlies.indexOf(entity.start) < 0) {
      form.setErrors({ global: 'Hora fim não deve ser menor que Hora início' })
    } else if (endHourlies.indexOf(entity.end) - startHourlies.indexOf(entity.start) > 0) {
      let index = startHourlies.indexOf(entity.start)
      const hoursSelected = []
      for (index; index <= endHourlies.indexOf(entity.end); index += 1) {
        hoursSelected.push({ start: startHourlies[index], end: endHourlies[index] })
      }
      service
        .post('scheduling.checkavailabletime', {
          hoursSelected,
          createdAt: entity.createdAt,
          lawyerId: entity.lawyerId,
          id: entity.id,
        })
        .then(() => {
          const firstHour = startHourlies.indexOf(entity.start)
          form.handleSubmit(data =>
            service
              .addOrUpdate('scheduling', {
                ...data,
                start: startHourlies[firstHour],
                end: endHourlies[firstHour],
              })
              .then(async res => {
                const firstScheduling = res.id
                for (let i = firstHour + 1; i <= endHourlies.indexOf(entity.end); i += 1) {
                  service.addOrUpdate('scheduling', {
                    ...data,
                    start: startHourlies[i],
                    end: endHourlies[i],
                    relatedScheduling: firstScheduling,
                  })
                }
                form.setSubmitting(false)
                resetForm()
                route.history.push(`${basename}/agendamento`, paramsRoute)
              })
              .catch(err => form.setErrors({ global: err.message })),
          )
        })
        .catch(err => form.setErrors({ global: err.message }))
    } else {
      form.handleSubmit(data =>
        service
          .addOrUpdate('scheduling', data)
          .then(() => {
            form.setSubmitting(false)
            resetForm()
            route.history.push(`${basename}/agendamento`, paramsRoute)
          })
          .catch(err => form.setErrors({ global: err.message })),
      )
    }
  }

  useEffect(() => {
    if (entity.disabledAt) {
      onSubmit()
    }
    // eslint-disable-next-line
  }, [entity.disabledAt])

  return (
    <ModalPortal>
      <Transition in={show} timeout={300}>
        {status => (
          <>
            <div
              className={classNames('modal fade', {
                show: status === 'entered',
              })}
              style={{
                display: status === 'exited' ? 'none' : 'block',
              }}
              tabIndex={-1}
              role="dialog"
              aria-modal="true"
            >
              <div role="document" className="modal-dialog modal-dialog-centered modal-xl">
                <div className="modal-content">
                  <form
                    onSubmit={ev => {
                      ev.preventDefault()
                      onSubmit()
                    }}
                  >
                    <div className="modal-header">
                      <h5 className="modal-title">
                        {form.displayName
                          ? `Editar Agendamento ${form.displayName}`
                          : 'Novo Agendamento'}
                      </h5>

                      <Button
                        type="button"
                        className="close"
                        aria-label="close"
                        data-dismiss="modal"
                        onClick={() => {
                          resetForm()
                          route.history.push(`${basename}/agendamento`)
                        }}
                      />
                    </div>
                    <div className="modal-body">
                      {form.fetching ? (
                        <div className="spinner">
                          <span
                            className="spinner-border spinner-border-sm"
                            role="status"
                            aria-hidden="true"
                          />
                          <span>Carregando...</span>
                        </div>
                      ) : (
                        <div className="kt-portlet__body">
                          <ul className="nav nav-tabs kt-mb-0" role="tablist">
                            <li className="nav-item">
                              <button
                                className={classNames('nav-link', {
                                  active: entity.view === 'Patients',
                                })}
                                onClick={() =>
                                  form.setValues({
                                    view: 'Patients',
                                  })
                                }
                                type="button"
                              >
                                <span className="btn kt-padding-0">Servidor</span>
                              </button>
                            </li>
                            <li className="nav-item">
                              <button
                                className={classNames('nav-link', {
                                  active: entity.view === 'Scheduling',
                                  disabled:
                                    entity.userId === null ||
                                    (entity.contactName === null && entity.contactPhone === null),
                                })}
                                onClick={() =>
                                  form.setValues({
                                    view: 'Scheduling',
                                  })
                                }
                                type="button"
                              >
                                <span className="btn kt-padding-0">Agendamento</span>
                              </button>
                            </li>
                          </ul>
                          <div className="border border-top-0 rounded-bottom p-3">
                            {entity.view === 'Patients' && (
                              <RenderPatient
                                form={form}
                                service={service}
                                global={global}
                                basename={basename}
                                setAttendanceWithoutPatient={setAttendanceWithoutPatient}
                              />
                            )}
                            {entity.view === 'Scheduling' && (
                              <RenderScheduling
                                form={form}
                                service={service}
                                global={global}
                                startHourlies={startHourlies}
                                endHourlies={endHourlies}
                              />
                            )}
                          </div>
                          <br />
                          <ErrorMessage error={errors.global} />
                          {attendanceWithoutPatient && (
                            <div className="alert alert-outline-primary" role="alert">
                              <div className="alert-icon">
                                <i className="fas fa-exclamation-triangle" />
                              </div>
                              <div className="alert-text">
                                É necessário um servidor cadastrado para iniciar um atendimento.
                                Deseja cadastrar?
                              </div>
                              <div className="alert-close">
                                <div className="col-lg kt-align-right">
                                  <Button
                                    customClassName="btn-info btn-icon-sm"
                                    title="Cadastrar"
                                    onClick={() => {
                                      route.history.push(`${basename}/clientes/cadastro`, {
                                        routeReturn: 'scheduling',
                                        params: {
                                          lawyerId: entity.lawyerId,
                                          lawyerProfessionalName: entity.lawyerProfessionalName,
                                          createdAt: entity.createdAt,
                                          start: entity.start,
                                          end: entity.end,
                                          schedulingId: form.hasId ? entity.id : null,
                                          contactName: entity.contactName,
                                          contactPhone: entity.contactPhone,
                                          relatedScheduling: entity.relatedScheduling,
                                        },
                                      })
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      )}
                    </div>

                    <div className="modal-footer">
                      <Button
                        type="button"
                        customClassName="btn-secondary"
                        icon="fas fa-arrow-left"
                        title="Voltar"
                        onClick={() => {
                          route.history.push(`${basename}/agendamento`)
                          resetForm()
                        }}
                      />
                      {!form.fetching && form.hasId && (
                        <Button
                          customClassName="btn-danger"
                          title="Cancelar"
                          icon="fas fa-calendar-times"
                          onClick={() => form.setValues({ disabledAt: new Date() })}
                        />
                      )}
                      {!form.fetching && (
                        <Button
                          customClassName="btn-primary"
                          title="Salvar"
                          icon="fas fa-save"
                          disabled={form.submitting}
                          loading={form.submitting}
                        />
                      )}
                    </div>
                  </form>
                </div>
              </div>
            </div>

            {status !== 'exited' && (
              <div
                className={classNames('modal-backdrop fade', {
                  show: status === 'entered',
                })}
              />
            )}
          </>
        )}
      </Transition>
    </ModalPortal>
  )
}
