import { DateInput } from "core/components/form/date-input"
import { Select } from "core/components/form/select"
import { BirthdateMask, CivilStatusDescription } from "core/constants"
import { TextInput } from "core/components/form/text-input"
import React from "react"

export const UserForm = ({ form: { entity, errors, touched, handleChange } }) => (
  <>
    <div className="form-group validated">
      <TextInput
        placeholder="E-mail"
        type="email"
        icon="fas fa-at"
        meta={{
          error: errors.email,
          touched: touched.email,
        }}
        value={entity.email}
        onChange={(email, type) =>
          handleChange({
            path: 'email',
            type,
            values: prev => ({
              ...prev,
              email,
            }),
          })
        }
      />
    </div>

    <div className="form-group validated">
      <TextInput
        placeholder="RG"
        type="rg"
        icon="fas fa-id-card"
        value={entity.rg}
        meta={{
          error: errors.rg,
          touched: touched.rg,
        }}
        onChange={(rg, type) =>
          handleChange({
            path: 'rg',
            type,
            values: prev => ({
              ...prev,
              rg,
            }),
          })
        }
      />
    </div>

    <div className="form-group validated">
      <DateInput
        placeholder="Data de Nascimento"
        icon="fas fa-calendar-alt"
        customClassName="form-control-xl"
        meta={{
          error: errors.birthDate,
          touched: touched.birthDate,
        }}
        mask={BirthdateMask}
        onChange={(birthDate, type) => {
          handleChange({
            path: 'birthDate',
            type,
            values: prev => ({
              ...prev,
              birthDate,
            }),
          })
        }}
        value={entity.birthDate || null}
      />
    </div>

    <div className="form-group validated">
      <Select
        placeholder="Estado Civil"
        meta={{
          error: errors.civilStatus,
          touched: touched.civilStatus,
        }}
        items={CivilStatusDescription}
        selected={entity.civilStatus}
        getId={({ id }) => id}
        getDisplay={({ name }) => name}
        onChange={civilStatus => {
          handleChange({
            path: 'workFunction',
            values: prev => ({
              ...prev,
              civilStatus: civilStatus.id,
            }),
          })
        }}
      />
    </div>
  </>
)
