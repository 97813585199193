import React, { useEffect, useRef } from 'react'
import { useForm } from 'core/hooks/form'
import { isCPF, isPhone } from 'core/helpers/validate'
import {
  BirthdateMask,
  CellPhoneMask,
  CivilStatus,
  CivilStatusDescription,
  CpfMask,
  Gender,
  PhoneMask,
} from 'core/constants'
import { Field } from 'core/components/form/field'
import { TextInput } from 'core/components/form/text-input'
import { ErrorMessage } from 'core/components/form/error-message'
import { applyMask } from 'core/helpers/mask'
import { DateInput } from 'core/components/form/date-input'
import { DecimalInput } from 'core/components/form/decimal-input'
import { isObject } from 'core/helpers/misc'
import { Select } from 'core/components/form/select'
import { AddressForm } from 'components/address'
import { submit } from 'helpers'
import { Button } from 'core/components/button'

export const AffiliationForm = ({ route, service, basename }) => {
  const form = useForm(
    {
      displayName: ent => ent.name,
      initialEntity: {
        name: '',
        socialName: '',
        others: '',
        cellPhone: '',
        nationality: '',
        cpf: '',
        phone: '',
        father: '',
        mother: '',
        email: '',
        rg: '',
        birthDate: null,
        civilStatus: CivilStatus.SINGLE,
        gender: Gender.FEMALE,
        address: {
          postalCode: '',
          streetName: '',
          streetNumber: '',
          district: '',
          city: '',
          state: '',
          complement: '',
        },
        workCity: '',
        secretary: '',
        workPlace: '',
        workFunction: '',
        workPhone: '',
        admittedAt: null,
        salary: 0,
      },
      validate: values => {
        const errors = {}

        if (!values.name) {
          errors.name = 'Informe um nome'
        }

        if (!values.socialName) {
          errors.socialName = 'Informe um nome social'
        }

        if (!values.nationality) {
          errors.nationality = 'Informe uma nacionalidade'
        }

        if (!values.cpf) {
          errors.cpf = 'Informe um CPF'
        }

        if (!isCPF(values.cpf)) {
          errors.cpf = 'Digite um CPF válido'
        }

        if (!values.cellPhone) {
          errors.cellPhone = 'Informe um número de celular'
        }

        if (!isPhone(values.cellPhone)) {
          errors.cellPhone = 'Digite um número de celular válido'
        }

        if (!values.address.postalCode) {
          errors.postalCode = 'Informe um CEP'
        }

        if (!values.address.streetName) {
          errors.streetName = 'Informe um endereço'
        }

        if (!values.address.streetNumber) {
          errors.streetNumber = 'Informe um número'
        }

        if (!values.address.district) {
          errors.district = 'Informe um bairro'
        }

        if (!values.address.city) {
          errors.city = 'Informe uma cidade'
        }

        if (!values.address.state) {
          errors.state = 'Informe um estado'
        }

        if (!values.gender) {
          errors.gender = 'Informe um gênero'
        }

        if (!values.civilStatus) {
          errors.civilStatus = 'Informe um estado civil'
        }

        if (!values.rg) {
          errors.rg = 'Informe um RG'
        }

        if (!values.birthDate) {
          errors.birthDate = 'Informe uma data de nascimento'
        }

        if (!values.father) {
          errors.father = 'Informe o nome do pai'
        }

        if (!values.mother) {
          errors.mother = 'Informe o nome da mãe'
        }

        if (!values.email) {
          errors.email = 'Informe um e-mail'
        }

        return errors
      },
    },
    route,
  )

  const { entity, errors, touched } = form
  const fetching = form.fetching || (form.hasId && entity.id === 0)

  useEffect(() => {
    if (form.hasId) {
      form.handleFetch({
        action: (id, ac) => service.getById('affiliation', id, ac.signal),
        errorFn: err => form.setErrors({ global: err.message }),
        mapper: e => ({
          ...e,
          address: {
            postalCode: e.postalCode,
            streetName: e.streetName,
            streetNumber: e.streetNumber,
            district: e.district,
            city: e.city,
            state: e.state,
            complement: e.complement,
          },
          cellPhone: applyMask(CellPhoneMask, e.cellPhone),
          phone: e.phone ? applyMask(PhoneMask, e.phone) : '',
          workPhone: applyMask(PhoneMask, e.workPhone),
        }),
      })
    }
    // eslint-disable-next-line
  }, [])

  const cpfInputRef = useRef(null)

  useEffect(() => {
    if (errors.cpf && cpfInputRef.current) {
      cpfInputRef.current.focus()
    }
  }, [errors.cpf])

  return fetching ? (
    'Carregando...'
  ) : (
    <div className="kt-portlet">
      <div className="kt-portlet__head">
        <div className="kt-portlet__head-label">
          <h3 className="kt-portlet__head-title">{`Solicitação de Filiação: ${form.displayName}`}</h3>
        </div>
      </div>
      <form
        autoComplete="off"
        className="kt-form kt-form--label-right"
        onSubmit={ev => {
          ev.preventDefault()
          form.handleSubmit(data => {
            submit({
              path: 'affiliation',
              callback: () => route.history.push(`${basename}/solicitacoes`),
              data,
              service,
              form,
            })
          })
        }}
      >
        <div className="kt-portlet__body">
          <div className="row">
            <div className="col-lg">
              <Field label="Nome">
                <TextInput
                  value={entity.name}
                  meta={{
                    error: errors.name,
                    touched: touched.name,
                  }}
                  onChange={(name, type) =>
                    form.handleChange({
                      path: 'name',
                      type,
                      values: prev => ({
                        ...prev,
                        name,
                      }),
                    })
                  }
                />
              </Field>
            </div>

            <div className="col-lg">
              <Field label="Nome Social">
                <TextInput
                  value={entity.socialName}
                  meta={{
                    error: errors.socialName,
                    touched: touched.socialName,
                  }}
                  onChange={(socialName, type) =>
                    form.handleChange({
                      path: 'socialName',
                      type,
                      values: prev => ({
                        ...prev,
                        socialName,
                      }),
                    })
                  }
                />
              </Field>
            </div>

            <div className="col-lg">
              <Field label="CPF">
                <TextInput
                  ref={cpfInputRef}
                  meta={{
                    error: errors.cpf,
                    touched: errors.cpf ? true : touched.cpf,
                  }}
                  mask={CpfMask}
                  value={applyMask(CpfMask, entity.cpf)}
                  onChange={(cpf, type) =>
                    form.handleChange({
                      path: 'cpf',
                      type,
                      values: prev => ({
                        ...prev,
                        cpf,
                      }),
                    })
                  }
                  disabled={isCPF(entity.cpf)}
                />
              </Field>
            </div>
          </div>

          <div className="row">
            <div className="col-lg">
              <Field label="Celular">
                <TextInput
                  type="tel"
                  icon="fas fa-phone"
                  mask={CellPhoneMask}
                  value={entity.cellPhone}
                  meta={{
                    error: errors.cellPhone,
                    touched: touched.cellPhone,
                  }}
                  onChange={(cellPhone, type) =>
                    form.handleChange({
                      path: 'cellPhone',
                      type,
                      values: { cellPhone },
                    })
                  }
                />
              </Field>
            </div>

            <div className="col-lg">
              <Field label="Telefone">
                <TextInput
                  type="tel"
                  icon="fas fa-phone"
                  mask={PhoneMask}
                  value={entity.phone}
                  meta={{
                    error: errors.phone,
                    touched: touched.phone,
                  }}
                  onChange={(phone, type) =>
                    form.handleChange({
                      path: 'phone',
                      type,
                      values: { phone },
                    })
                  }
                />
              </Field>
            </div>

            <div className="col-lg">
              <Field label="Nacionalidade">
                <TextInput
                  value={entity.nationality}
                  meta={{
                    error: errors.nationality,
                    touched: touched.nationality,
                  }}
                  onChange={(nationality, type) =>
                    form.handleChange({
                      path: 'nationality',
                      type,
                      values: prev => ({
                        ...prev,
                        nationality,
                      }),
                    })
                  }
                />
              </Field>
            </div>

            <div className="col-lg">
              <Field label="Outras Informações">
                <TextInput
                  value={entity.others}
                  meta={{
                    error: errors.others,
                    touched: touched.others,
                  }}
                  onChange={(others, type) =>
                    form.handleChange({
                      path: 'others',
                      type,
                      values: prev => ({
                        ...prev,
                        others,
                      }),
                    })
                  }
                />
              </Field>
            </div>
          </div>

          <div className="row">
            <div className="col-lg">
              <Field label="Nome do Pai">
                <TextInput
                  value={entity.father}
                  meta={{
                    error: errors.father,
                    touched: touched.father,
                  }}
                  onChange={(father, type) =>
                    form.handleChange({
                      path: 'father',
                      type,
                      values: prev => ({
                        ...prev,
                        father,
                      }),
                    })
                  }
                />
              </Field>
            </div>

            <div className="col-lg">
              <Field label="Nome da Mãe">
                <TextInput
                  value={entity.mother}
                  meta={{
                    error: errors.mother,
                    touched: touched.mother,
                  }}
                  onChange={(mother, type) =>
                    form.handleChange({
                      path: 'mother',
                      type,
                      values: prev => ({
                        ...prev,
                        mother,
                      }),
                    })
                  }
                />
              </Field>
            </div>

            <div className="col-lg">
              <Field label="E-mail">
                <TextInput
                  type="email"
                  meta={{
                    error: errors.email,
                    touched: touched.email,
                  }}
                  value={entity.email}
                  onChange={(email, type) =>
                    form.handleChange({
                      path: 'email',
                      type,
                      values: prev => ({
                        ...prev,
                        email,
                      }),
                    })
                  }
                />
              </Field>
            </div>
          </div>

          <div className="row">
            <div className="col-lg">
              <Field label="RG">
                <TextInput
                  type="rg"
                  value={entity.rg}
                  meta={{
                    error: errors.rg,
                    touched: touched.rg,
                  }}
                  onChange={(rg, type) =>
                    form.handleChange({
                      path: 'rg',
                      type,
                      values: prev => ({
                        ...prev,
                        rg,
                      }),
                    })
                  }
                />
              </Field>
            </div>

            <div className="col-lg">
              <Field label="Data de Nascimento">
                <DateInput
                  customClassName="form-control-xl"
                  meta={{
                    error: errors.birthDate,
                    touched: touched.birthDate,
                  }}
                  mask={BirthdateMask}
                  onChange={(birthDate, type) => {
                    form.handleChange({
                      path: 'birthDate',
                      type,
                      values: prev => ({
                        ...prev,
                        birthDate,
                      }),
                    })
                  }}
                  value={entity.birthDate || null}
                />
              </Field>
            </div>

            <div className="col-lg">
              <Field label="Estado Civil">
                <Select
                  meta={{
                    error: errors.civilStatus,
                    touched: touched.civilStatus,
                  }}
                  items={CivilStatusDescription}
                  selected={entity.civilStatus}
                  getId={({ id }) => id}
                  getDisplay={({ name }) => name}
                  onChange={civilStatus => {
                    form.handleChange({
                      path: 'workFunction',
                      values: prev => ({
                        ...prev,
                        civilStatus: civilStatus.id,
                      }),
                    })
                  }}
                />
              </Field>
            </div>
          </div>

          <div className="row">
            <div className="col-lg">
              <Field label="Município">
                <TextInput
                  value={entity.workCity}
                  onChange={(workCity, type) =>
                    form.handleChange({
                      path: 'workCity',
                      type,
                      values: prev => ({
                        ...prev,
                        workCity,
                      }),
                    })
                  }
                />
              </Field>
            </div>

            <div className="col-lg">
              <Field label="Secretaria">
                <TextInput
                  value={entity.secretary}
                  onChange={(secretary, type) =>
                    form.handleChange({
                      path: 'secretary',
                      type,
                      values: prev => ({
                        ...prev,
                        secretary,
                      }),
                    })
                  }
                />
              </Field>
            </div>

            <div className="col-lg">
              <Field label="Local de Trabalho">
                <TextInput
                  value={entity.workPlace}
                  onChange={(workPlace, type) =>
                    form.handleChange({
                      path: 'workPlace',
                      type,
                      values: prev => ({
                        ...prev,
                        workPlace,
                      }),
                    })
                  }
                />
              </Field>
            </div>
          </div>

          <div className="row">
            <div className="col-lg">
              <Field label="Cargo ou Função">
                <TextInput
                  value={entity.workFunction}
                  onChange={(workFunction, type) =>
                    form.handleChange({
                      path: 'workFunction',
                      type,
                      values: prev => ({
                        ...prev,
                        workFunction,
                      }),
                    })
                  }
                />
              </Field>
            </div>
            <div className="col-lg">
              <Field label="Tel. do Trabalho">
                <TextInput
                  type="tel"
                  mask={PhoneMask}
                  value={entity.workPhone}
                  meta={{
                    error: errors.workPhone,
                    touched: touched.workPhone,
                  }}
                  onChange={(workPhone, type) =>
                    form.handleChange({
                      path: 'workPhone',
                      type,
                      values: prev => ({
                        ...prev,
                        workPhone,
                      }),
                    })
                  }
                />
              </Field>
            </div>

            <div className="col-lg">
              <Field label="Data de Admissão">
                <DateInput
                  customClassName="form-control-xl"
                  meta={{
                    error: errors.admittedAt,
                    touched: touched.admittedAt,
                  }}
                  mask={BirthdateMask}
                  onChange={(admittedAt, type) => {
                    form.handleChange({
                      path: 'admittedAt',
                      type,
                      values: prev => ({
                        ...prev,
                        admittedAt,
                      }),
                    })
                  }}
                  value={entity.admittedAt || null}
                />
              </Field>
            </div>

            <div className="col-lg">
              <Field label="Salário">
                <DecimalInput
                  meta={{
                    error: errors.salary,
                    touched: touched.salary,
                  }}
                  acceptEnter
                  customClassName="form-control-xl"
                  icon="fas fa-dollar-sign"
                  onChange={(salary, type) => {
                    form.handleChange({
                      path: 'salary',
                      type,
                      values: prev => ({
                        ...prev,
                        salary,
                      }),
                    })
                  }}
                  value={entity.salary}
                />
              </Field>
            </div>
          </div>

          <div className="kt-separator kt-separator--border-dashed kt-separator--space-lg" />
          <div className="kt-heading kt-heading--sm">Endereço</div>

          <AddressForm
            address={entity.address}
            errors={errors}
            touched={touched}
            setValues={(property, value, type) => {
              if (isObject(property)) {
                form.handleChange({
                  type: 'object',
                  values: prev => ({
                    ...prev,
                    address: {
                      ...prev.address,
                      ...property,
                    },
                  }),
                })
              } else {
                entity.address[property] = value
                form.handleChange({
                  path: property,
                  type,
                  values: prev => ({
                    ...prev,
                    address: entity.address,
                  }),
                })
              }
            }}
          />

          <ErrorMessage error={errors.global} />
        </div>

        <div className="kt-portlet__foot">
          <div className="kt-form__actions">
            <div className="row">
              <div className="col-lg kt-align-right">
                <Button
                  type="button"
                  icon="fas fa-arrow-left"
                  customClassName="btn-secondary"
                  title="Voltar"
                  disabled={form.submitting}
                  onClick={() => route.history.goBack()}
                />
                <Button
                  icon="fas fa-save"
                  customClassName="btn-primary"
                  title="Salvar"
                  loading={form.submitting}
                  disabled={form.submitting}
                />
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  )
}
