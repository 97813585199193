import React, { useEffect, useState, useRef } from 'react'
import { useForm } from 'core/hooks/form'
import {
  requiredMessage,
  CpfMask,
  CellPhoneMask,
  PhoneMask,
  Banks,
  BanksDescription,
  BirthdateMask,
  CivilStatus,
  CivilStatusDescription,
  Gender,
  GenderDescription,
} from 'core/constants'
import { isCPF } from 'core/helpers/validate'
import { Field } from 'core/components/form/field'
import { TextInput } from 'core/components/form/text-input'
import { submit } from 'helpers'
import { isEmail } from 'core/helpers/validate'
import { Button } from 'core/components/button'
import { ErrorMessage } from 'core/components/form/error-message'
import { AddressForm } from '../address'
import { isObject } from 'core/helpers/misc'
import { classNames } from 'core/helpers/misc'
import { Select } from 'core/components/form/select'
import { Switch } from 'core/components/form/switch'
import { AsyncSelect } from 'core/components/form/async-select'
import { DateInput } from 'core/components/form/date-input'
import { DecimalInput } from 'core/components/form/decimal-input'
import { useRefresh } from 'core/hooks/refresh'
import { useFilters } from 'core/hooks/filter'
import { List } from 'core/components/list'
import { toLocaleDate } from 'core/helpers/date'
import { iframeDownload } from 'core/helpers/misc'

const findById = (items, selectId) => {
  const encontred = items.find(item => selectId === item.id)
  if (encontred) {
    return true
  } else {
    return false
  }
}

const RenderInitial = ({ form }) => {
  const { entity, errors, touched } = form

  const cpfInputRef = useRef(null)

  useEffect(() => {
    if (errors.cpf && cpfInputRef.current) {
      cpfInputRef.current.focus()
    }
  }, [errors.cpf])

  return (
    <>
      <div className="row">
        <div className="col-lg">
          <Field label="Nome">
            <TextInput
              meta={{
                error: errors.name,
                touched: touched.name,
              }}
              value={entity.name}
              onChange={(name, type) =>
                form.handleChange({
                  path: 'name',
                  type,
                  values: prev => ({
                    ...prev,
                    name,
                  }),
                })
              }
            />
          </Field>
        </div>
        <div className="col-lg">
          <Field label="CPF">
            <TextInput
              ref={cpfInputRef}
              meta={{
                error: errors.cpf,
                touched: errors.cpf ? true : touched.cpf,
              }}
              mask={CpfMask}
              value={entity.cpf}
              onChange={(cpf, type) =>
                form.handleChange({
                  path: 'cpf',
                  type,
                  values: prev => ({
                    ...prev,
                    cpf,
                  }),
                })
              }
              disabled={isCPF(entity.cpf)}
            />
          </Field>
        </div>
        <div className="col-lg">
          <Field label="Nome Social">
            <TextInput
              meta={{
                error: errors.socialName,
                touched: touched.socialName,
              }}
              value={entity.socialName}
              onChange={(socialName, type) =>
                form.handleChange({
                  path: 'socialName',
                  type,
                  values: prev => ({
                    ...prev,
                    socialName,
                  }),
                })
              }
            />
          </Field>
        </div>
      </div>

      <div className="row">
        <div className="col-lg">
          <Field label="Matrícula">
            <TextInput
              value={entity.registration}
              onChange={(registration, type) =>
                form.handleChange({
                  path: 'registration',
                  type,
                  values: prev => ({
                    ...prev,
                    registration,
                  }),
                })
              }
            />
          </Field>
        </div>
        <div className="col-lg">
          <Field label="Rg">
            <TextInput
              type="rg"
              value={entity.rg}
              onChange={(rg, type) =>
                form.handleChange({
                  path: 'rg',
                  type,
                  values: prev => ({
                    ...prev,
                    rg,
                  }),
                })
              }
            />
          </Field>
        </div>
        <div className="col-lg">
          <Field label="E-mail">
            <TextInput
              type="email"
              icon="fas fa-at"
              meta={{
                error: errors.email,
                touched: touched.email,
              }}
              value={entity.email}
              onChange={(email, type) =>
                form.handleChange({
                  path: 'email',
                  type,
                  values: prev => ({
                    ...prev,
                    email,
                  }),
                })
              }
            />
          </Field>
        </div>
      </div>
      <div className="row">
        <div className="col-lg">
          <Field label="Telefone Residencial">
            <TextInput
              type="tel"
              icon="fas fa-phone"
              mask={PhoneMask}
              value={entity.residencialPhone}
              onChange={(residencialPhone, type) =>
                form.handleChange({
                  path: 'residencialPhone',
                  type,
                  values: { residencialPhone },
                })
              }
            />
          </Field>
        </div>
        <div className="col-lg">
          <Field label="Telefone Comercial">
            <TextInput
              type="tel"
              icon="fas fa-phone"
              mask={PhoneMask}
              value={entity.comercialPhone}
              onChange={(comercialPhone, type) =>
                form.handleChange({
                  path: 'comercialPhone',
                  type,
                  values: { comercialPhone },
                })
              }
            />
          </Field>
        </div>
        <div className="col-lg">
          <Field label="Celular">
            <TextInput
              type="tel"
              icon="fas fa-phone"
              mask={CellPhoneMask}
              value={entity.cellPhone}
              onChange={(cellPhone, type) =>
                form.handleChange({
                  path: 'cellPhone',
                  type,
                  values: { cellPhone },
                })
              }
            />
          </Field>
        </div>
        <div className="col-lg-2">
          <Switch
            id="whatsapp"
            label="WhatsApp"
            checked={entity.whatsApp}
            className={'button-add switch-layout'}
            onChange={(whatsApp, type) => {
              form.handleChange({
                path: 'whatsApp',
                type,
                values: prev => ({
                  ...prev,
                  whatsApp,
                }),
              })
            }}
          />
        </div>
        <div className="col-lg">
          <Field label="Celular 2 ">
            <TextInput
              type="tel"
              icon="fas fa-phone"
              mask={CellPhoneMask}
              value={entity.cellPhone2}
              onChange={(cellPhone2, type) =>
                form.handleChange({
                  path: 'cellPhone2',
                  type,
                  values: { cellPhone2 },
                })
              }
            />
          </Field>
        </div>
        <div className="col-lg-2">
          <Switch
            id="whatsapp"
            label="WhatsApp"
            checked={entity.whatsApp2}
            className={'button-add switch-layout'}
            onChange={(whatsApp2, type) => {
              form.handleChange({
                path: 'whatsApp2',
                type,
                values: prev => ({
                  ...prev,
                  whatsApp2,
                }),
              })
            }}
          />
        </div>
      </div>

      <div className="row">
        <div className="col-lg">
          <Field label="Data de Nasc.">
            <DateInput
              customClassName="form-control-xl"
              meta={{
                error: errors.birthDate,
                touched: touched.birthDate,
              }}
              mask={BirthdateMask}
              onChange={(birthDate, type) => {
                form.handleChange({
                  path: 'birthDate',
                  type,
                  values: prev => ({
                    ...prev,
                    birthDate,
                  }),
                })
              }}
              value={entity.birthDate || null}
            />
          </Field>
        </div>
        <div className="col-lg">
          <Field label="Gênero">
            <Select
              meta={{
                error: errors.gender,
                touched: touched.gender,
              }}
              items={GenderDescription}
              selected={entity.gender}
              getId={({ id }) => id}
              getDisplay={({ name }) => name}
              onChange={gender => {
                form.handleChange({
                  path: 'workFunction',
                  values: prev => ({
                    ...prev,
                    gender: gender.id,
                  }),
                })
              }}
            />
          </Field>
        </div>
        <div className="col-lg">
          <Field label="Estado Civil">
            <Select
              meta={{
                error: errors.civilStatus,
                touched: touched.civilStatus,
              }}
              items={CivilStatusDescription}
              selected={entity.civilStatus}
              getId={({ id }) => id}
              getDisplay={({ name }) => name}
              onChange={civilStatus => {
                form.handleChange({
                  path: 'workFunction',
                  values: prev => ({
                    ...prev,
                    civilStatus: civilStatus.id,
                  }),
                })
              }}
            />
          </Field>
        </div>
      </div>

      <div className="row">
        <div className="col-lg">
          <Field label="Mãe">
            <TextInput
              value={entity.mother}
              onChange={(mother, type) =>
                form.handleChange({
                  path: 'mother',
                  type,
                  values: prev => ({
                    ...prev,
                    mother,
                  }),
                })
              }
            />
          </Field>
        </div>
        <div className="col-lg">
          <Field label="Pai">
            <TextInput
              value={entity.father}
              onChange={(father, type) =>
                form.handleChange({
                  path: 'father',
                  type,
                  values: prev => ({
                    ...prev,
                    father,
                  }),
                })
              }
            />
          </Field>
        </div>
      </div>

      <div className="kt-separator kt-separator--border-dashed kt-separator--space-lg" />
      <div className="kt-heading kt-heading--sm">Endereço</div>

      <AddressForm
        address={entity.address}
        errors={errors}
        touched={touched}
        setValues={(property, value, type) => {
          if (isObject(property)) {
            form.handleChange({
              type: 'object',
              values: prev => ({
                ...prev,
                address: {
                  ...prev.address,
                  ...property,
                },
              }),
            })
          } else {
            entity.address[property] = value
            form.handleChange({
              path: property,
              type,
              values: prev => ({
                ...prev,
                address: entity.address,
              }),
            })
          }
        }}
      />
    </>
  )
}

const RenderFinancial = ({ form }) => {
  const { entity, errors, touched } = form
  return (
    <>
      <div className="row">
        <div className="col-lg-2">
          <Switch
            id="fee"
            label="Gerar Taxa"
            checked={entity.fee}
            className={'button-add switch-layout'}
            onChange={(fee, type) => {
              form.handleChange({
                path: 'fee',
                type,
                values: prev => ({
                  ...prev,
                  fee,
                }),
              })
            }}
          />
        </div>
        <div className="col-lg">
          <Field label="Mensalidade Sindical">
            <DecimalInput
              meta={{
                error: errors.unionFee,
                touched: touched.unionFee,
              }}
              acceptEnter
              customClassName="form-control-xl"
              icon="fas fa-dollar-sign"
              onChange={(unionFee, type) => {
                form.handleChange({
                  path: 'unionFee',
                  type,
                  values: prev => ({
                    ...prev,
                    unionFee,
                  }),
                })
              }}
              value={entity.unionFee}
            />
          </Field>
        </div>
      </div>

      <div className="row">
        <div className="col-lg">
          <Field label="Banco">
            <Select
              meta={{
                error: errors.bank,
                touched: touched.bank,
              }}
              items={BanksDescription}
              selected={entity.bank}
              getId={({ id }) => id}
              getDisplay={({ name }) => name}
              onChange={bank => {
                form.handleChange({
                  path: 'bank',
                  values: prev => ({
                    ...prev,
                    bank: bank.id,
                  }),
                })
              }}
            />
          </Field>
        </div>

        <div className="col-lg-2">
          <Field label="Tipo Conta">
            <TextInput
              value={entity.typeAccount}
              onChange={(typeAccount, type) =>
                form.handleChange({
                  path: 'typeAccount',
                  type,
                  values: prev => ({
                    ...prev,
                    typeAccount,
                  }),
                })
              }
            />
          </Field>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-2">
          <Field label="Agência">
            <TextInput
              value={entity.agency}
              onChange={(agency, type) =>
                form.handleChange({
                  path: 'agency',
                  type,
                  values: prev => ({
                    ...prev,
                    agency,
                  }),
                })
              }
            />
          </Field>
        </div>
        <div className="col-lg">
          <Field label="Conta">
            <TextInput
              value={entity.account}
              onChange={(account, type) =>
                form.handleChange({
                  path: 'account',
                  type,
                  values: prev => ({
                    ...prev,
                    account,
                  }),
                })
              }
            />
          </Field>
        </div>
        <div className="col-lg-1">
          <Field label="Dígito">
            <TextInput
              value={entity.digitAccount}
              onChange={(digitAccount, type) =>
                form.handleChange({
                  path: 'digitAccount',
                  type,
                  values: prev => ({
                    ...prev,
                    digitAccount,
                  }),
                })
              }
            />
          </Field>
        </div>
      </div>
      <div className="row">
        <div className="col-lg">
          <Field label="Salário Contratual">
            <DecimalInput
              meta={{
                error: errors.contractualSalary,
                touched: touched.contractualSalary,
              }}
              acceptEnter
              customClassName="form-control-xl"
              icon="fas fa-dollar-sign"
              onChange={(contractualSalary, type) => {
                form.handleChange({
                  path: 'contractualSalary',
                  type,
                  values: prev => ({
                    ...prev,
                    contractualSalary,
                  }),
                })
              }}
              value={entity.contractualSalary}
            />
          </Field>
        </div>
        <div className="col-lg">
          <Field label="Biênio">
            <DecimalInput
              meta={{
                error: errors.biennium,
                touched: touched.biennium,
              }}
              acceptEnter
              customClassName="form-control-xl"
              icon="fas fa-dollar-sign"
              onChange={(biennium, type) => {
                form.handleChange({
                  path: 'biennium',
                  type,
                  values: prev => ({
                    ...prev,
                    biennium,
                  }),
                })
              }}
              value={entity.biennium}
            />
          </Field>
        </div>
        <div className="col-lg">
          <Field label="Previdência">
            <DecimalInput
              meta={{
                error: errors.foresight,
                touched: touched.foresight,
              }}
              acceptEnter
              customClassName="form-control-xl"
              icon="fas fa-dollar-sign"
              onChange={(foresight, type) => {
                form.handleChange({
                  path: 'foresight',
                  type,
                  values: prev => ({
                    ...prev,
                    foresight,
                  }),
                })
              }}
              value={entity.foresight}
            />
          </Field>
        </div>
        <div className="col-lg">
          <Field label="Sassom">
            <DecimalInput
              meta={{
                error: errors.sassom,
                touched: touched.sassom,
              }}
              acceptEnter
              customClassName="form-control-xl"
              icon="fas fa-dollar-sign"
              onChange={(sassom, type) => {
                form.handleChange({
                  path: 'sassom',
                  type,
                  values: prev => ({
                    ...prev,
                    sassom,
                  }),
                })
              }}
              value={entity.sassom}
            />
          </Field>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-2">
          <Field label="Provento">
            <DecimalInput
              meta={{
                error: errors.profit,
                touched: touched.profit,
              }}
              acceptEnter
              customClassName="form-control-xl"
              icon="fas fa-dollar-sign"
              onChange={(profit, type) => {
                form.handleChange({
                  path: 'profit',
                  type,
                  values: prev => ({
                    ...prev,
                    profit,
                  }),
                })
              }}
              value={entity.profit}
            />
          </Field>
        </div>
        <div className="col-lg">
          <Field label="Descrição do Provento">
            <TextInput
              value={entity.profitDescription}
              onChange={(profitDescription, type) =>
                form.handleChange({
                  path: 'profitDescription',
                  type,
                  values: prev => ({
                    ...prev,
                    profitDescription,
                  }),
                })
              }
            />
          </Field>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-2">
          <Field label="Desconto">
            <DecimalInput
              meta={{
                error: errors.discount,
                touched: touched.discount,
              }}
              acceptEnter
              customClassName="form-control-xl"
              icon="fas fa-dollar-sign"
              onChange={(discount, type) => {
                form.handleChange({
                  path: 'discount',
                  type,
                  values: prev => ({
                    ...prev,
                    discount,
                  }),
                })
              }}
              value={entity.discount}
            />
          </Field>
        </div>
        <div className="col-lg">
          <Field label="Descrição do Desconto">
            <TextInput
              value={entity.discountDescription}
              onChange={(discountDescription, type) =>
                form.handleChange({
                  path: 'discountDescription',
                  type,
                  values: prev => ({
                    ...prev,
                    discountDescription,
                  }),
                })
              }
            />
          </Field>
        </div>
      </div>
    </>
  )
}

const RenderAnother = ({ form, service }) => {
  const { entity, errors, touched } = form
  const [secretaries, setSecretaries] = useState([])
  const [workplaces, setWorkplaces] = useState([])
  const [workfunctions, setWorkfunctions] = useState([])

  useEffect(() => {
    if (entity.userLocalId) {
      service
        .getList('secretary', {
          filters: [
            {
              items: [
                {
                  name: 'localId',
                  comparer: 'Equals',
                  value: entity.userLocalId,
                },
                {
                  name: 'disabledAt',
                  comparer: 'Equals',
                  value: null,
                },
              ],
            },
          ],
          sort: [['description', 'ASC']],
          usePager: false,
        })
        .then(({ items }) => {
          if (items.length > 0) {
            setSecretaries(items)
            form.setValues(prev => ({
              ...prev,
              secretaryId: entity.secretaryId
                ? findById(items, entity.secretaryId)
                  ? entity.secretaryId
                  : items[0].id
                : items[0].id,
            }))
          } else {
            form.setValues(prev => ({
              ...prev,
              secretaryId: '',
              workPlaceId: '',
            }))
            setSecretaries([])
            setWorkplaces([])
          }
        })
    } else {
      form.setValues(prev => ({
        ...prev,
        secretaryId: '',
        workPlaceId: '',
      }))
      setSecretaries([])
      setWorkplaces([])
    }

    // eslint-disable-next-line
  }, [entity.userLocalId])

  useEffect(() => {
    if (entity.userLocalId) {
      service
        .getList('workplace', {
          filters: [
            {
              items: [
                {
                  name: 'WorkPlace.localId',
                  comparer: 'Equals',
                  value: entity.userLocalId,
                },
              ],
            },
          ],
          sort: [['description', 'ASC']],
          usePager: false,
        })
        .then(({ items }) => {
          if (items.length > 0) {
            setWorkplaces(items)
            form.setValues(prev => ({
              ...prev,
              workPlaceId: entity.workPlaceId
                ? findById(items, entity.workPlaceId)
                  ? entity.workPlaceId
                  : ''
                : items[0].id,
            }))
          } else {
            form.setValues(prev => ({
              ...prev,
              workPlaceId: '',
            }))
            setWorkplaces([])
          }
        })
    } else {
      form.setValues(prev => ({
        ...prev,
        workPlaceId: '',
      }))
      setWorkplaces([])
    }
    // eslint-disable-next-line
  }, [entity.userLocalId])

  useEffect(() => {
    if (entity.userLocalId) {
      service
        .getList('workfunction', {
          filters: [
            {
              items: [
                {
                  name: 'WorkFunction.localId',
                  comparer: 'Equals',
                  value: entity.userLocalId,
                },
              ],
            },
          ],
          sort: [['description', 'ASC']],
          usePager: false,
        })
        .then(({ items }) => {
          if (items.length > 0) {
            setWorkfunctions(items)
            form.setValues(prev => ({
              ...prev,
              workFunctionId: entity.workFunctionId
                ? findById(items, entity.workFunctionId)
                  ? entity.workFunctionId
                  : ''
                : items[0].id,
            }))
          } else {
            form.setValues(prev => ({
              ...prev,
              workFunctionId: '',
            }))
            setWorkfunctions([])
          }
        })
    } else {
      form.setValues(prev => ({
        ...prev,
        workFunctionId: '',
      }))
      setWorkfunctions([])
    }
    // eslint-disable-next-line
  }, [entity.userLocalId])

  return (
    <>
      <div className="row">
        <div className="col-lg">
          <Field label="Município">
            <AsyncSelect
              meta={{
                error: errors.userLocalId,
                touched: touched.userLocalId,
              }}
              isClearable
              getId={({ id }) => id}
              getDisplay={({ description }) => description}
              getItems={value =>
                service
                  .getList('local', {
                    sort: [['description', 'ASC']],
                    filters: [
                      {
                        items: [
                          {
                            name: 'description',
                            comparer: 'Like',
                            value,
                          },
                        ],
                      },
                    ],
                    usePager: false,
                  })
                  .then(({ items }) => items)
              }
              selected={entity.userLocalId}
              onChange={local => {
                form.handleChange({
                  path: 'userLocalId',
                  values: prev => ({
                    ...prev,
                    userLocalId: local ? local.id : 0,
                  }),
                })
              }}
            />
          </Field>
        </div>
        <div className="col-lg">
          <Field label="Secretaria">
            <Select
              meta={{
                error: errors.secretaryId,
                touched: touched.secretaryId,
              }}
              items={secretaries}
              selected={entity.secretaryId}
              getId={({ id }) => id}
              getDisplay={({ description }) => description}
              onChange={secretary => {
                form.handleChange({
                  path: 'secretary',
                  values: prev => ({
                    ...prev,
                    secretaryId: secretary.id,
                  }),
                })
              }}
            />
          </Field>
        </div>
        <div className="col-lg">
          <Field label="Local de Trabalho">
            <Select
              meta={{
                error: errors.workPlaceId,
                touched: touched.workPlaceId,
              }}
              items={workplaces}
              selected={entity.workPlaceId}
              getId={({ id }) => id}
              getDisplay={({ description }) => description}
              onChange={workPlace => {
                form.handleChange({
                  path: 'workPlace',
                  values: prev => ({
                    ...prev,
                    workPlaceId: workPlace.id,
                  }),
                })
              }}
            />
          </Field>
        </div>
      </div>
      <div className="row">
        <div className="col-lg">
          <Field label="Função">
            <Select
              meta={{
                error: errors.workFunctionId,
                touched: touched.workFunctionId,
              }}
              items={workfunctions}
              selected={entity.workFunctionId}
              getId={({ id }) => id}
              getDisplay={({ description }) => description}
              onChange={workFunction => {
                form.handleChange({
                  path: 'workFunction',
                  values: prev => ({
                    ...prev,
                    workFunctionId: workFunction.id,
                  }),
                })
              }}
            />
          </Field>
        </div>

        <div className="col-lg">
          <Field label="Data de Admissão">
            <DateInput
              customClassName="form-control-xl"
              meta={{
                error: errors.admittedAt,
                touched: touched.admittedAt,
              }}
              mask={BirthdateMask}
              onChange={(admittedAt, type) => {
                form.handleChange({
                  path: 'admittedAt',
                  type,
                  values: prev => ({
                    ...prev,
                    admittedAt,
                  }),
                })
              }}
              value={entity.admittedAt || null}
            />
          </Field>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-3">
          <Field label="Data de Aposentadoria">
            <DateInput
              customClassName="form-control-xl"
              meta={{
                error: errors.retiredAt,
                touched: touched.retiredAt,
              }}
              mask={BirthdateMask}
              onChange={(retiredAt, type) => {
                form.handleChange({
                  path: 'retiredAt',
                  type,
                  values: prev => ({
                    ...prev,
                    retiredAt,
                  }),
                })
              }}
              value={entity.retiredAt || null}
            />
          </Field>
        </div>
        <div className="col-lg-3">
          <Field label="Data de Afastamento">
            <DateInput
              customClassName="form-control-xl"
              meta={{
                error: errors.awayAt,
                touched: touched.awayAt,
              }}
              mask={BirthdateMask}
              onChange={(awayAt, type) => {
                form.handleChange({
                  path: 'awayAt',
                  type,
                  values: prev => ({
                    ...prev,
                    awayAt,
                  }),
                })
              }}
              value={entity.awayAt || null}
            />
          </Field>
        </div>
        <div className="col-lg-3">
          <Field label="Data de Exoneração">
            <DateInput
              customClassName="form-control-xl"
              meta={{
                error: errors.exoneratedAt,
                touched: touched.exoneratedAt,
              }}
              disabled={!entity.disaffiliated}
              mask={BirthdateMask}
              onChange={(exoneratedAt, type) => {
                form.handleChange({
                  path: 'exoneratedAt',
                  type,
                  values: prev => ({
                    ...prev,
                    exoneratedAt,
                  }),
                })
              }}
              value={entity.exoneratedAt || null}
            />
          </Field>
        </div>
      </div>
    </>
  )
}

const RenderAffiliate = ({ form }) => {
  const { entity, errors, touched } = form

  return (
    <>
      <div className="row">
        <div className="col-lg">
          <Field label="Data">
            <DateInput
              customClassName="form-control-xl"
              meta={{
                error: errors.affiliateDate,
                touched: touched.affiliateDate,
              }}
              onChange={(affiliateDate, type) => {
                form.handleChange({
                  path: 'affiliateDate',
                  type,
                  values: prev => ({
                    ...prev,
                    affiliateDate,
                  }),
                })
              }}
              value={entity.affiliateDate}
            />
          </Field>
        </div>

        <div className="col-lg">
          <Field label="Motivo">
            <TextInput
              value={entity.reason}
              onChange={(reason, type) =>
                form.handleChange({
                  path: 'reason',
                  type,
                  values: prev => ({
                    ...prev,
                    reason,
                  }),
                })
              }
            />
          </Field>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-2">
          <Switch
            id="disaffiliated"
            label={entity.disaffiliated ? 'Desfiliado' : 'Filiado'}
            checked={!entity.disaffiliated}
            className={'switch-layout'}
            onChange={(disaffiliated, type) => {
              form.handleChange({
                path: 'disaffiliated',
                type,
                values: prev => ({
                  ...prev,
                  disaffiliated: !disaffiliated,
                  affiliateChanged: !prev.affiliateChanged,
                }),
              })
            }}
          />
        </div>
      </div>

      <div className="row kt-mt-30">
        <div className="col-lg">
          <h3 className="kt-portlet__head-title">Histórico</h3>
          <div className="kt-datatable kt-datatable--default kt-datatable--brand kt-datatable--loaded">
            <table className="kt-datatable__table">
              <thead className="kt-datatable__head">
                <tr className="kt-datatable__row">
                  <th className="kt-datatable__cell">
                    <span>Data</span>
                  </th>
                  <th className="kt-datatable__cell">
                    <span>Filiado</span>
                  </th>
                  <th className="kt-datatable__cell">
                    <span>Motivo</span>
                  </th>
                </tr>
              </thead>
              <tbody className="kt-datatable__body">
                {entity.history.map(history => (
                  <tr key={history.id} className="kt-datatable__row">
                    <td className="kt-datatable__cell">
                      <div>{toLocaleDate(history.createdAt, true)}</div>
                    </td>
                    <td className="kt-datatable__cell">
                      <div>{history.affiliate ? 'Sim' : 'Não'}</div>
                    </td>
                    <td className="kt-datatable__cell">
                      <div>{history.reason}</div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  )
}

const RenderDefault = ({ form }) => {
  const { entity, errors, touched } = form

  return (
    <>
      <div className="row">
        <div className="col-lg">
          <Field label="Data">
            <DateInput
              customClassName="form-control-xl"
              meta={{
                error: errors.defaultDate,
                touched: touched.defaultDate,
              }}
              onChange={(defaultDate, type) => {
                form.handleChange({
                  path: 'defaultDate',
                  type,
                  values: prev => ({
                    ...prev,
                    defaultDate,
                  }),
                })
              }}
              value={entity.defaultDate}
            />
          </Field>
        </div>

        <div className="col-lg">
          <Field label="Motivo">
            <TextInput
              value={entity.reasonDefault}
              onChange={(reasonDefault, type) =>
                form.handleChange({
                  path: 'reasonDefault',
                  type,
                  values: prev => ({
                    ...prev,
                    reasonDefault,
                  }),
                })
              }
            />
          </Field>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-2">
          <Switch
            id="defaulter"
            label={entity.defaulter ? 'Inadimplente' : 'Em Dia'}
            checked={entity.defaulter}
            className={'switch-layout'}
            onChange={(defaulter, type) => {
              form.handleChange({
                path: 'defaulter',
                type,
                values: prev => ({
                  ...prev,
                  defaulter: defaulter,
                  defaulterChanged: !prev.defaulterChanged,
                }),
              })
            }}
          />
        </div>
      </div>

      <div className="row kt-mt-30">
        <div className="col-lg">
          <h3 className="kt-portlet__head-title">Histórico</h3>
          <div className="kt-datatable kt-datatable--default kt-datatable--brand kt-datatable--loaded">
            <table className="kt-datatable__table">
              <thead className="kt-datatable__head">
                <tr className="kt-datatable__row">
                  <th className="kt-datatable__cell">
                    <span>Data</span>
                  </th>
                  <th className="kt-datatable__cell">
                    <span>Inadimplente</span>
                  </th>
                  <th className="kt-datatable__cell">
                    <span>Motivo</span>
                  </th>
                </tr>
              </thead>
              <tbody className="kt-datatable__body">
                {entity.historyDefault.map(historyDefault => (
                  <tr key={historyDefault.id} className="kt-datatable__row">
                    <td className="kt-datatable__cell">
                      <div>{toLocaleDate(historyDefault.createdAt, true)}</div>
                    </td>
                    <td className="kt-datatable__cell">
                      <div>{historyDefault.defaulter ? 'Sim' : 'Não'}</div>
                    </td>
                    <td className="kt-datatable__cell">
                      <div>{historyDefault.reason}</div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  )
}

const RenderLogs = ({ service, route, modal, form }) => {
  const { entity } = form
  const columns = [
    { path: 'id', title: '#', textAlign: 'center', sort: 'index' },
    { path: 'employeeName', title: 'Colaborador' },
    { path: 'field', title: 'Campo Alterado' },
    { path: 'oldValue', title: 'Valor Anterior' },
    { path: 'newValue', title: 'Valor novo' },
    { path: 'createdAt', title: 'Data de Alteração', format: 'date' },
  ]

  const { filters } = useFilters({ userId: entity.id }, query => [
    {
      items: [{ name: 'userId', value: query.userId, comparer: 'Equals' }],
    },
  ])

  const fetching = useState(false)
  const refresh = useRefresh()

  return (
    <>
      <div className="kt-portlet__body kt-portlet__body--fit">
        <div className="kt-separator kt-separator--space-sm" />

        <List
          primaryKey="id"
          modal={modal}
          route={route}
          refresh={refresh}
          fetching={fetching}
          filters={filters}
          initialQuery={{ fields: columns.map(c => c.path), sort: [['createdAt', 'DESC']] }}
          getItems={(query, signal) => service.getList('userlog', query, signal)}
          actions={[]}
          columns={columns}
        />
      </div>
    </>
  )
}

const RenderDocs = ({ service, route, modal, form }) => {
  const { entity } = form
  const [downloading, setDownloading] = useState(false)
  function handleProcurationDownload() {
    setDownloading(true)
    service
      .post('user.procuration', { ...entity }, undefined, resp => resp.blob())
      .then(blob => iframeDownload(blob, 'procuração.pdf'))
      .catch(err => modal.alert(err.message))
      .finally(() => setDownloading(false))
  }
  function handledDeclarationDownload() {
    setDownloading(true)
    service
      .post('user.declaration', { ...entity }, undefined, resp => resp.blob())
      .then(blob => iframeDownload(blob, 'Declaração.pdf'))
      .catch(err => modal.alert(err.message))
      .finally(() => setDownloading(false))
  }
  function handleDisaffiliationDownload() {
    if (entity.disaffiliated) {
      setDownloading(true)
      service
        .post('user.disaffiliation', { ...entity }, undefined, resp => resp.blob())
        .then(blob => iframeDownload(blob, 'Desfiliação.pdf'))
        .catch(err => modal.alert(err.message))
        .finally(() => setDownloading(false))
    } else modal.alert('Servidor Filiado, Faça a Desfiliação para continuar')
  }
  function handleWaiverDownload() {
    if (entity.disaffiliated) {
      setDownloading(true)
      service
        .post('user.waiver', { ...entity }, undefined, resp => resp.blob())
        .then(blob => iframeDownload(blob, 'Renuncia.pdf'))
        .catch(err => modal.alert(err.message))
        .finally(() => setDownloading(false))
    } else modal.alert('Servidor Filiado, Faça a Desfiliação para continuar')
  }

  return (
    <>
      <div className="kt-portlet__body kt-portlet__body--fit">
        <div className="kt-separator kt-separator--space-sm" />

        <ul>
          <li className="nav-item">
            <Button
              icon="fas fa-file-pdf"
              onClick={handleProcurationDownload}
              disabled={downloading}
              title="Gerar Procuração"
            />
          </li>
          <li className="nav-item">
            <Button
              icon="fas fa-file-pdf"
              onClick={handledDeclarationDownload}
              disabled={downloading}
              title="Gerar Declaração"
            />
          </li>
          <li className="nav-item">
            <Button
              icon="fas fa-file-pdf"
              onClick={handleDisaffiliationDownload}
              disabled={downloading}
              title="Gerar Termo de desfiliação"
            />
          </li>
          <li className="nav-item">
            <Button
              icon="fas fa-file-pdf"
              onClick={handleWaiverDownload}
              disabled={downloading}
              title="Gerar Termo de renuncia"
            />
          </li>
        </ul>
      </div>
    </>
  )
}

export const UserForm = ({ route, service, basename, global }) => {
  const { modal } = global
  const form = useForm(
    {
      displayName: ent => ent.name,
      initialEntity: {
        id: 0,
        name: '',
        cpf: '',
        socialName: '',
        disaffiliated: false,
        email: '',
        rg: '',
        registration: '',
        residencialPhone: '',
        comercialPhone: '',
        cellPhone: '',
        cellPhone2: '',
        bank: Banks.BRASIL,
        agency: '',
        typeAccount: '',
        account: '',
        digitAccount: '',
        fee: false,
        userLocalId: '',
        secretaryId: '',
        workFunctionId: '',
        workPlaceId: '',
        father: '',
        mother: '',
        reason: '',
        birthDate: null,
        civilStatus: CivilStatus.SINGLE,
        gender: Gender.FEMALE,
        contractualSalary: 0,
        biennium: 0,
        foresight: 0,
        sassom: 0,
        profit: 0,
        profitDescription: '',
        discount: 0,
        discountDescription: '',
        unionFee: 0,
        retiredAt: null,
        admittedAt: null,
        affiliateDate: null,
        whatsApp: false,
        whatsApp2: false,
        //
        history: [],
        historyDefault: [],
        address: {
          postalCode: '',
          streetName: '',
          streetNumber: '',
          district: '',
          city: '',
          state: '',
          complement: '',
        },
        view: 'Initial',
        defaultDate: null,
        reasonDefault: '',
        defaulter: false,
        awayAt: null,
        exoneratedAt: null,
      },
      validate: values => {
        const errors = {}

        if (!values.name) errors.name = requiredMessage
        else if (values.name.length < 8) errors.name = 'O nome deve ter pelo menos 8 caracteres.'
        else if (values.name.length > 100) errors.name = 'O nome deve ter no máximo 100 caracteres.'

        if (!values.cpf) errors.cpf = requiredMessage
        else if (!isCPF(values.cpf)) errors.cpf = 'Digite um CPF válido.'

        if (values.email && !isEmail(values.email))
          errors.email = 'Digite um endereço de email válido.'

        if (!values.userLocalId) errors.userLocalId = requiredMessage
        if (!values.secretaryId) errors.secretaryId = requiredMessage
        if (!values.workFunctionId) errors.workFunctionId = requiredMessage
        if (!values.workPlaceId) errors.workPlaceId = requiredMessage
        if (!values.affiliateDate && (!route.match.params.id || values.affiliateChanged))
          errors.affiliateDate = requiredMessage
        if (!values.defaultDate && (!route.match.params.id || values.defaulterChanged))
          errors.defaultDate = requiredMessage

        return errors
      },
    },
    route,
  )

  const { entity, errors } = form
  const fetching = form.fetching || (form.hasId && entity.id === 0)

  useEffect(() => {
    if (form.hasId) {
      form.handleFetch({
        action: (id, ac) => service.getById('user', id, ac.signal),
        errorFn: err => form.setErrors({ global: err.message }),
      })
    }
    // eslint-disable-next-line
  }, [])

  return fetching ? (
    'Carregando...'
  ) : (
    <div className="kt-portlet">
      <div className="kt-portlet__head">
        <div className="kt-portlet__head-label">
          <h3 className="kt-portlet__head-title">
            {form.displayName ? `Editar ${form.displayName}` : 'Novo Servidor'}
          </h3>
        </div>
      </div>
      <form
        autoComplete="off"
        className="kt-form kt-form--label-right"
        onSubmit={ev => {
          ev.preventDefault()
          form.handleSubmit(data => {
            submit({
              path: 'user',
              callback: () => route.history.push(`${basename}/servidores`),
              data,
              service,
              form,
            })
          })
        }}
      >
        <div className="kt-portlet__body">
          <ul className="nav nav-tabs kt-mb-0" role="tablist">
            <li className="nav-item">
              <button
                className={classNames('nav-link', {
                  active: entity.view === 'Initial',
                })}
                onClick={() =>
                  form.setValues({
                    view: 'Initial',
                  })
                }
                type="button"
              >
                <span className="btn kt-padding-0">Dados Iniciais</span>
              </button>
            </li>
            <li className="nav-item">
              <button
                className={classNames('nav-link', {
                  active: entity.view === 'Another',
                })}
                onClick={() =>
                  form.setValues({
                    view: 'Another',
                  })
                }
                type="button"
              >
                <span className="btn kt-padding-0">Outros Dados</span>
              </button>
            </li>
            <li className="nav-item">
              <button
                className={classNames('nav-link', {
                  active: entity.view === 'Financial',
                })}
                onClick={() =>
                  form.setValues({
                    view: 'Financial',
                  })
                }
                type="button"
              >
                <span className="btn kt-padding-0">Dados Bancários e Financeiros</span>
              </button>
            </li>
            <li className="nav-item">
              <button
                className={classNames('nav-link', {
                  active: entity.view === 'Logs',
                })}
                onClick={() =>
                  form.setValues({
                    view: 'Logs',
                  })
                }
                type="button"
              >
                <span className="btn kt-padding-0">Logs de Alterações</span>
              </button>
            </li>
            <li className="nav-item">
              <button
                className={classNames('nav-link', {
                  active: entity.view === 'Affiliate',
                })}
                onClick={() =>
                  form.setValues({
                    view: 'Affiliate',
                  })
                }
                type="button"
              >
                <span className="btn kt-padding-0">Filiação</span>
              </button>
            </li>
            <li className="nav-item">
              <button
                className={classNames('nav-link', {
                  active: entity.view === 'Default',
                })}
                onClick={() =>
                  form.setValues({
                    view: 'Default',
                  })
                }
                type="button"
              >
                <span className="btn kt-padding-0">Inadimplência</span>
              </button>
            </li>
            <li className="nav-item">
              <button
                className={classNames('nav-link', {
                  active: entity.view === 'Docs',
                })}
                onClick={() =>
                  form.setValues({
                    view: 'Docs',
                  })
                }
                type="button"
              >
                <span className="btn kt-padding-0">Documentos</span>
              </button>
            </li>
          </ul>
          <div className="border border-top-0 rounded-bottom p-3">
            {entity.view === 'Initial' && <RenderInitial form={form} />}
            {entity.view === 'Another' && <RenderAnother form={form} service={service} />}
            {entity.view === 'Financial' && <RenderFinancial form={form} />}
            {entity.view === 'Logs' && (
              <RenderLogs service={service} route={route} modal={modal} form={form} />
            )}
            {entity.view === 'Affiliate' && (
              <RenderAffiliate service={service} route={route} modal={modal} form={form} />
            )}
            {entity.view === 'Default' && (
              <RenderDefault service={service} route={route} modal={modal} form={form} />
            )}
            {entity.view === 'Docs' && (
              <RenderDocs service={service} route={route} modal={modal} form={form} />
            )}
          </div>
          <br />

          <ErrorMessage error={errors.global} />
        </div>
        <div className="kt-portlet__foot">
          <div className="kt-form__actions">
            <div className="row">
              <div className="col-lg kt-align-right">
                <Button
                  type="button"
                  icon="fas fa-arrow-left"
                  customClassName="btn-secondary"
                  title="Voltar"
                  disabled={form.submitting}
                  onClick={() => route.history.goBack()}
                />
                <Button
                  icon="fas fa-save"
                  customClassName="btn-primary"
                  title="Salvar"
                  loading={form.submitting}
                  disabled={form.submitting}
                />
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  )
}
